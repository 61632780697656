import React, { Component } from "react";
import { ReactComponent as BackArrow } from "assets/icons/backArrow.svg";
import { ReactComponent as UnitedKingdomFlag } from "assets/flags/united-kingdom.svg";
import { ReactComponent as ChinaFlag } from "assets/flags/china.svg";
import { ReactComponent as JapanFlag } from "assets/flags/japan.svg";

import "component/menu/Language/styles.scss";

class Language extends Component {
  constructor(props) {
    super(props);
    this.setLocale = this._setLocale.bind(this);
    this.getLocale = this._getLocale.bind(this);
    this.state = {
      show: false,
    };
  }

  _getLocale(locale, key) {
    let ActiveFlag;

    switch (locale.get('id')) {
      case "zh_CN":
        ActiveFlag = ChinaFlag;
        break;
      case "ja_JP":
        ActiveFlag = JapanFlag;
        break;
      default:
        ActiveFlag = UnitedKingdomFlag;
        break;
    }

    return (
        <div
        className={`col-6 flag-icon text-center ${
          locale.get('enabled')  ? "toggled" : ""
        }`}
        key={key}
        onClick={() => {
            this.setLocale(locale.get('id'));
        }}
        >
        <div className="icon">
            <ActiveFlag />
            <div className="flag-name">{locale.get('name')}</div>
        </div>
      </div>
    );
  }

  _setLocale(locale) {
    if (this.props.setLocale) this.props.setLocale(locale);
  }

  render() {
    return (
      <div
        className={`language ${
          this.props.className ? this.props.className : ""
        } ${this.props.isMobile ? "mobile" : ""} ${
          this.props.show ? "show" : ""
        }`}
      >
        <div className="header">{this.props.header}</div>
        <div className="content">
          <div className="row ">
            {
              this.props.supportedLanguagesLink && this.props.supportedLanguagesLink.get('items').map((language, key) => {
                return this.getLocale(language, key)
              })
            }
          </div>
        </div>
        <span
          className="backArrow"
          onClick={() => {
            this.props.onClose();
          }}
        >
          <BackArrow />
        </span>
      </div>
    );
  }
}

export default Language;
