import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreator } from "actions";
import "containers/GameNavigation/styles.scss";
import { ReactComponent as ScratchCardsIcon } from "assets/icons/scratch cards.svg";
import { ReactComponent as SlotIconActive } from "assets/icons/Slots-active.svg";
import { ReactComponent as SlotIconInactive } from "assets/icons/Slots-inactive.svg";
import { ReactComponent as TableGamesIconActive } from "assets/icons/TableGames-active.svg";
import { ReactComponent as TableGamesIconInactive } from "assets/icons/TableGames-inactive.svg";
import { ReactComponent as VirtualSportsIconActive } from "assets/icons/VirtualSports-active.svg";
import { ReactComponent as VirtualSportsIconInactive } from "assets/icons/VirtualSports-inactive.svg";
import { ReactComponent as CrashGamesIconActive } from "assets/icons/CrashGames-active.svg";
import { ReactComponent as CrashGamesIconInactive } from "assets/icons/CrashGames-inactive.svg";
import { ReactComponent as LiveCasinoIconActive } from "assets/icons/LiveCasino-active.svg";
import { ReactComponent as LiveCasinoIconInactive } from "assets/icons/LiveCasino-inactive.svg";
import { ReactComponent as InstantWinIconActive } from "assets/icons/InstantWin-active.svg";
import { ReactComponent as InstantWinIconInactive } from "assets/icons/InstantWin-inactive.svg";
import { ReactComponent as LotteryIconActive } from "assets/icons/Lottery-active.svg";
import { ReactComponent as LotteryIconInactive } from "assets/icons/Lottery-inactive.svg";
import { ReactComponent as ShootingIconActive } from "assets/icons/Shooting-active.svg";
import { ReactComponent as ShootingIconInactive } from "assets/icons/Shooting-inactive.svg";
import { ReactComponent as GameShowsIconActive } from "assets/icons/GameShows-active.svg";
import { ReactComponent as GameShowsIconInactive } from "assets/icons/GameShows-inactive.svg";
import { ReactComponent as EsportsIconActive } from "assets/icons/ESports-active.svg";
import { ReactComponent as EsportsIconInactive } from "assets/icons/Esports-inactive.svg";
import {
  HomeIcon,
  BingoIcon,
  VirtualGamesIcon,
  DiscoverIcon,
} from "component/icons";
import Translations from "translations";
import MainNavigationSkeleton from "component/listItemSkeleton/MainNavigationSkeleton";

import HeaderBannerDefault from "assets/images/headerBannerDefault.png";
import { setDefaultImageFormat } from "utils/image";

class GameNavigation extends Component {
  constructor(props) {
    super(props);
    this.setBannerSrc = this._setBannerSrc.bind(this);
    this.getIcon = this._getIcon.bind(this);
    this.state = {
      showMenu: false,
      iconSize: {
        width: 0,
        height: 0,
      },
    };
  }

  _setBannerSrc(match) {
    let bannerUrl;
    switch (match.params.page) {
      case "wl-slots":
        bannerUrl = () => {
          try {
            return `${setDefaultImageFormat(
              this.props.gameLobbyBannerUrls.getIn(["SLOT_GAMES"]),
              this.props.imageFormat.get("gamelobbyBanner")
            )}`;
          } catch (err) {
            return HeaderBannerDefault;
          }
        };
        break;
      case "wl-tableGames":
        bannerUrl = () => {
          try {
            return `${setDefaultImageFormat(
              this.props.gameLobbyBannerUrls.getIn(["TABLE_GAMES"]),
              this.props.imageFormat.get("gamelobbyBanner")
            )}`;
          } catch (err) {
            return HeaderBannerDefault;
          }
        };
        break;
      case "wl-instantWinGames":
        bannerUrl = () => {
          try {
            return `${setDefaultImageFormat(
              this.props.gameLobbyBannerUrls.getIn(["INSTANT_WIN"]),
              this.props.imageFormat.get("gamelobbyBanner")
            )}`;
          } catch (err) {
            return HeaderBannerDefault;
          }
        };
        break;
      case "wl-virtualSportsGames":
        bannerUrl = () => {
          try {
            return `${setDefaultImageFormat(
              this.props.gameLobbyBannerUrls.getIn(["VIRTUAL_SPORTS"]),
              this.props.imageFormat.get("gamelobbyBanner")
            )}`;
          } catch (err) {
            return HeaderBannerDefault;
          }
        };
        break;
      case "wl-virtualGameGames":
        bannerUrl = () => {
          return HeaderBannerDefault;
        };
        break;
      case "wl-scratchCardGames":
        bannerUrl = () => {
          try {
            return `${setDefaultImageFormat(
              this.props.gameLobbyBannerUrls.getIn(["SCRATCH_CARDS"]),
              this.props.imageFormat.get("gamelobbyBanner")
            )}`;
          } catch (err) {
            return HeaderBannerDefault;
          }
        };
        break;
      case "wl-bingoGames":
        bannerUrl = () => {
          try {
            return `${setDefaultImageFormat(
              this.props.gameLobbyBannerUrls.getIn(["BINGO_GAMES"]),
              this.props.imageFormat.get("gamelobbyBanner")
            )}`;
          } catch (err) {
            return HeaderBannerDefault;
          }
        };
        break;
      case "wl-shootingGames":
        bannerUrl = () => {
          try {
            return `${setDefaultImageFormat(
              this.props.gameLobbyBannerUrls.getIn(["SHOOTING_GAMES"]),
              this.props.imageFormat.get("gamelobbyBanner")
            )}`;
          } catch (err) {
            return HeaderBannerDefault;
          }
        };
        break;
      case "wl-liveCasinoGames":
        bannerUrl = () => {
          try {
            return `${setDefaultImageFormat(
              this.props.gameLobbyBannerUrls.getIn(["LIVE_CASINO"]),
              this.props.imageFormat.get("gamelobbyBanner")
            )}`;
          } catch (err) {
            return HeaderBannerDefault;
          }
        };
        break;
      case "wl-esportsGames":
        bannerUrl = () => {
          try {
            return `${setDefaultImageFormat(
              this.props.gameLobbyBannerUrls.getIn(["ESPORTS"]),
              this.props.imageFormat.get("gamelobbyBanner")
            )}`;
          } catch (err) {
            return HeaderBannerDefault;
          }
        };
        break;
      case "wl-lotteryGames":
        bannerUrl = () => {
          try {
            return `${setDefaultImageFormat(
              this.props.gameLobbyBannerUrls.getIn(["LOTTERY"]),
              this.props.imageFormat.get("gamelobbyBanner")
            )}`;
          } catch (err) {
            return HeaderBannerDefault;
          }
        };
        break;
      case "wl-CRASH":
        bannerUrl = () => {
          try {
            return `${setDefaultImageFormat(
              this.props.gameLobbyBannerUrls.getIn(["CRASH"]),
              this.props.imageFormat.get("gamelobbyBanner")
            )}`;
          } catch (err) {
            return HeaderBannerDefault;
          }
        };
        break;
      case "wl-GAME_SHOW":
        bannerUrl = () => {
          try {
            return `${setDefaultImageFormat(
              this.props.gameLobbyBannerUrls.getIn(["GAME_SHOW"]),
              this.props.imageFormat.get("gamelobbyBanner")
            )}`;
          } catch (err) {
            return HeaderBannerDefault;
          }
        };
        break;
      default:
        bannerUrl = () => {
          try {
            return `${setDefaultImageFormat(
              this.props.gameLobbyBannerUrls.getIn(["MY_CASINO"]),
              this.props.imageFormat.get("gamelobbyBanner")
            )}`;
          } catch (err) {
            return HeaderBannerDefault;
          }
        };
        break;
    }
    return bannerUrl();
  }

  _getIcon(content, key) {
    let isActive = this.props.match.params.page === content.get("rel");
    let Icon;

    switch (content.get("rel")) {
      case "wl-slots":
        Icon = isActive ? SlotIconActive : SlotIconInactive;
        break;
      case "wl-tableGames":
        Icon = isActive ? TableGamesIconActive : TableGamesIconInactive;
        break;
      case "wl-virtualSportsGames":
        Icon = isActive ? VirtualSportsIconActive : VirtualSportsIconInactive;
        break;
      case "wl-virtualGameGames":
        Icon = VirtualGamesIcon;
        break;
      case "wl-instantWinGames":
        Icon = isActive ? InstantWinIconActive : InstantWinIconInactive;
        break;
      case "wl-bingoGames":
        Icon = BingoIcon;
        break;
      case "wl-shootingGames":
        Icon = isActive ? ShootingIconActive : ShootingIconInactive;
        break;
      case "wl-scratchCardGames":
        Icon = ScratchCardsIcon;
        break;
      case "wl-liveCasinoGames":
        Icon = isActive ? LiveCasinoIconActive : LiveCasinoIconInactive;
        break;
      case "wl-esportsGames":
        Icon = isActive ? EsportsIconActive : EsportsIconInactive;
        break;
      case "wl-CRASH":
        Icon = isActive ? CrashGamesIconActive : CrashGamesIconInactive;
        break;
      case "wl-GAME_SHOW":
        Icon = isActive ? GameShowsIconActive : GameShowsIconInactive;
        break;
      case "wl-lotteryGames":
        Icon = isActive ? LotteryIconActive : LotteryIconInactive;
        break;
      default:
        Icon = HomeIcon;
        break;
    }
    let { locale, sessionId, page } = this.props.match.params;
    let { pathname } = this.props.location;

    return (
      <li
        key={key}
        className={`nav-item ${pathname === "/home"} ${
          page === content.get("rel") ? "selected" : ""
        }`}
      >
        <Link
          className="nav-link text-center"
          to={`/${locale}/${sessionId}/games/${content.get(
            "rel"
          )}/${content.getIn(["links", 1, "rel"])}`}
        >
          <Icon color="rgba(255,204,0,1)" selectedcolor="rgba(255,204,0,1)" />
          <div className="category-name">{content.get("name")}</div>
        </Link>
      </li>
    );
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.gameLobbyBannerUrls &&
      nextProps.gameLobbyBannerUrls !== this.props.gameLobbyBannerUrls
    ) {
      this.setState({
        bannerUrl: this.setBannerSrc(this.props.match),
      });
    }
    if (nextProps.match && nextProps.match !== this.props.match) {
      this.setState({
        bannerUrl: this.setBannerSrc(nextProps.match),
      });
    }
  }

  render() {
    let { locale, sessionId } = this.props.match.params;
    let bannerUrl = this.setBannerSrc(this.props.match);
    let isMyCasino = this.props.match.params.page === "myQT";

    return (
      <Fragment>
        {this.props.getPages && this.props.showBanner ? (
          <div className={`header-background-holder`}>
            <div
              className={`header-background ${
                bannerUrl !== undefined ? "withBanner" : ""
              }  ${
                this.props.filtersVisible && !isMyCasino ? "filter-visible" : ""
              }`}
              style={{ backgroundImage: `url(${bannerUrl})` }}
            >
              &nbsp;
            </div>
          </div>
        ) : (
          <div className="header-background no-banner">&nbsp;</div>
        )}
        <div
          className={`maingamenavigation ${
            bannerUrl !== undefined ? "withBanner" : ""
          } ${
            this.props.filtersVisible && !isMyCasino
              ? "gamenavigation-filter-visible"
              : ""
          }`}
        >
          <span
            className={`gamenavigation ${
              this.props.isMobile ? "mobile" : "desktop"
            }`}
          >
            <ul className="nav justify-content-center">
              {!this.props.myQT &&
                [...Array(10)].map((e, key) => (
                  <li className={`nav-item`} key={key}>
                    <span className={"nav-link text-center"}>
                      <MainNavigationSkeleton />
                    </span>
                  </li>
                ))}
              {this.props.myQT && (
                <li className={`nav-item ${isMyCasino ? "selected" : ""}`}>
                  <Link
                    className="nav-link text-center discover"
                    to={`/${locale}/${sessionId}/categories/${this.props.myQT.get(
                      "rel"
                    )}`}
                  >
                    <DiscoverIcon />
                    <div className="category-name">{Translations.discover}</div>
                  </Link>
                </li>
              )}
              {this.props.contents &&
                this.props.contents.map((content, key) => {
                  return this.getIcon(content, key);
                })}
            </ul>
          </span>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let contents;
  let myQT;
  let gameLobbyBannerUrls;
  let getPages;
  let showBanner;

  try {
    contents = state.pages
      .get("wl-pages")
      .find((page) => {
        return page.get("rel") === "webLobbyMain";
      })
      .get("content");
    gameLobbyBannerUrls = state.links.getIn(["gameLobbyBannerUrls"]);
    getPages = state.links.getIn(["getPages"]);
    showBanner = state.links.getIn(["showBanner"]);
  } catch (err) {}

  try {
    myQT = state.pages.get("wl-pages").find((page) => {
      return page.get("rel") === "myQT";
    });
  } catch (err) {}

  return {
    isMobile: state.window.get("isMobile"),
    filtersVisible: state.config.get("filtersVisible"),
    contents: contents,
    myQT: myQT,
    gameLobbyBannerUrls,
    width: state.window.get("width"),
    getPages,
    showBanner,
    imageFormat: state.window.get("imageFormat"),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(ActionCreator, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return Object.assign({}, stateProps, ownProps, {
    logout: () => dispatchProps.logout(),
  });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(GameNavigation);
