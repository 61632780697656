import React, { Component, Fragment, createRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreator } from "actions";
import { fromJS } from "immutable";
import CategoryVeritcalList from "containers/CategoryVerticalList";
import { TrophyIcon, RibbonIcon, EditorsChoiceIcon } from "component/icons";
import { ReactComponent as CrownIcon } from "assets/icons/crown.svg";
import { CategoryListItem } from "component/listItem";
import { ReactComponent as PreviousIcon } from "assets/icons/previous.svg";
import { ReactComponent as PlayDemo } from "assets/icons/play-demo.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ReactComponent as TransferIcon } from "assets/icons/transfer.svg";
import { ReactComponent as CashierIcon } from "assets/icons/cashier.svg";
import bannerDefault from "assets/images/bannerDefault.png";
import { PlayNow } from "component/icons";
import ReactGA from "react-ga4";

import * as queryString from "query-string";

import Translations from "translations";

import "containers/GameAction/styles.scss";
import { setDefaultImageFormat } from "utils/image";

const ACTION_MODAL = "ACTION_MODAL";

class GameAction extends Component {
  constructor(props) {
    super(props);

    this.modalRef = createRef();
    this.modalActionRef = createRef();

    this.onCloseClickHandler = this._onCloseClickHandler.bind(this);
    this.onExpandDetails = this._onExpandDetails.bind(this);
    this.onPlayClick = this._onPlayClick.bind(this);
    this.getDetails = this._getDetails.bind(this);
    this.fetchGameUrl = this._fetchGameUrl.bind(this);
    this.handleScroll = this._handleScroll.bind(this);
    this.fetchRelatedLinks = this._fetchRelatedLinks.bind(this);

    this.state = {
      isLoading: true,
      isLoadingRelated: true,
      details: null,
      relatedLinks: null,
      similarLink: null,
      launchRealLink: null,
      launchDemoLink: null,
      realUrl: null,
      demoUrl: null,
      expand: false,
      hasPromotionIcons: null,
      showCategoryLabel: false,
    };
  }

  _getDetails(gameId) {
    this.setState({
      isLoading: true,
      isLoadingRelated: true,
      details: null,
      relatedLinks: null,
      similarLink: null,
      launchRealLink: null,
      launchDemoLink: null,
      realUrl: null,
      demoUrl: null,
      hasPromotionIcons: null,
    });

    this.props
      .fetchGameDetailsUrl(gameId)
      .then((response) => {
        let link = fromJS(response);
        return this.props.fetchGameDetails(link);
      })
      .then((response) => {
        let details = fromJS(response.payload.data);
        let hasPromotionIcons =
          details.get("hasFreeRounds") ||
          details.get("partOfOnGoingTournament") ||
          details.get("isFeatured") ||
          details.get("isJackpot");
        this.setState({
          details,
          hasPromotionIcons,
        });
        return;
      })
      .then(() => {
        let realLink;

        try {
          realLink = this.state.details.get("links").find((link) => {
            return link.get("rel") === "launch.real";
          });
          return this.fetchGameUrl(realLink);
        } catch (err) {
          return;
        }
      })
      .then(() => {
        let demoLink;
        try {
          demoLink = this.state.details.get("links").find((link) => {
            return link.get("rel") === "launch.demo";
          });
          return this.fetchGameUrl(demoLink);
        } catch (err) {
          return;
        }
      })
      .then(() => {
        this.setState({
          isLoading: false,
        });
        let relatedLink;
        try {
          relatedLink = this.state.details.get("links").find((link) => {
            return link.get("rel") === "related.links";
          });
          let params = {
            nav: this.props.nav,
          };

          return this.fetchRelatedLinks(relatedLink, params);
        } catch (err) {
          return;
        }
      })
      .then(() => {
        this.setState({
          isLoadingRelated: false,
        });
      });
  }

  _fetchRelatedLinks(link, params) {
    return this.props.fetchRelatedLinks(link, params).then((response) => {
      this.setState({
        relatedLinks: fromJS(response),
      });
    });
  }

  _fetchGameUrl(link) {
    let params;
    let returnUrl;
    switch (this.state.details.get("launchTarget")) {
      case "_top":
      case "_self":
      case "_parent":
        let urlParams = new URLSearchParams(this.props.location.search);
        let redirectParams = new URLSearchParams();
        let url = `${this.props.location.pathname}?${urlParams.toString()}`;
        redirectParams.set("redirect", btoa(encodeURIComponent(url)));
        returnUrl = `${window.location.origin}/${
          this.props.match.params.locale
        }/${
          this.props.match.params.sessionId
        }/exit?${redirectParams.toString()}`;
        break;
      default:
        returnUrl = `${window.location.origin}/${
          this.props.match.params.locale
        }/${this.props.match.params.sessionId}/exit`;
        break;
    }
    params = {
      returnUrl,
    };

    if (this.props.appId) {
      params = {
        returnUrl: `${this.props.appId}://lobby`,
      };
    }

    return this.props.fetchGameUrl(link, params).then((response) => {
      if (link.get("rel") === "launch.real") {
        this.setState({
          launchRealLink: link,
          realUrl: response.payload.data.url,
        });
      } else if (link.get("rel") === "launch.demo") {
        this.setState({
          launchDemoLink: link,
          demoUrl: response.payload.data.url,
        });
      }
      return response;
    });
  }

  _onPlayClick(playType) {
    if (this.props.gameLaunchTarget === "_self") {
      let params = {
        gameId: this.props.gameId,
        playType: playType,
        nav: this.props.nav,
      };
      let newLoc = `${this.props.location.pathname}?${queryString.stringify(
        params
      )}`;
      this.props.navigateTo(newLoc);
      this.props.clearGameAction();
      return;
    }

    let params = {
      location: this.props.location,
      hideSplash: this.props.hideSplash,
      isMobile: this.props.isMobile,
      appId: this.props.appId,
      target: this.props.gameLaunchTarget,
    };

    switch (playType) {
      case "launch.demo":
        params.url = this.state.demoUrl;
        params.playType = this.state.launchDemoLink.get("rel");
        break;
      default:
        params.url = this.state.realUrl;
        params.playType = this.state.launchRealLink.get("rel");
        break;
    }
    this.props.onGameLaunch(params);
  }

  _onExpandDetails() {
    this.setState({
      expand: !this.state.expand,
    });
  }

  _onCloseClickHandler() {
    this.setState({
      expand: false,
    });
    this.props.fetchBalance();
    let search = queryString.parse(this.props.location.search);
    delete search.gameId;
    delete search.playType;
    delete search.nav;
    this.props.navigateTo(`${this.props.location.pathname}`);
  }

  componentDidMount() {
    if (this.props.gameId) {
      this.getDetails(this.props.gameId);
      //scroll to top
      this.props.showModal(ACTION_MODAL);
      this.setState({
        expand: true,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.gameActionDetails !== nextProps.gameActionDetails &&
      nextProps.gameActionDetails
    ) {
      if (nextProps.gameActionDetails === null) {
        this.props.hideModal(ACTION_MODAL);
        this.setState({
          expand: false,
        });
      } else {
        this.setState(nextProps.gameActionDetails);

        try {
          let relatedLink = nextProps.gameActionDetails.details
            .get("links")
            .find((link) => {
              return link.get("rel") === "related.links";
            });
          let params = {
            nav: this.props.nav,
          };
          this.fetchRelatedLinks(relatedLink, params);
        } catch (err) {}

        this.props.showModal(ACTION_MODAL);
      }
    }

    if (nextProps.gameId !== this.props.gameId) {
      if (nextProps.gameId === undefined || nextProps.gameId === "undefined") {
        this.props.hideModal(ACTION_MODAL);
        this.setState({
          expand: false,
        });
      } else {
        if (
          !this.state.details ||
          this.state.details.getIn(["gameId"]) !== nextProps.gameId
        ) {
          this.getDetails(nextProps.gameId);
        }
        this.props.showModal(ACTION_MODAL);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.gameActionDetails !== prevProps.gameActionDetails &&
      this.props.gameActionDetails &&
      this.modalRef.current
    ) {
      this.modalRef.current.scrollTo(0, 0);
    }

    if (
      prevProps.gameId !== this.props.gameId &&
      this.props.gameId &&
      this.modalRef.current
    ) {
      this.modalRef.current.scrollTo(0, 0);
    }
  }

  _handleScroll(event) {
    const { scrollTop } = event.currentTarget;
    if (scrollTop !== 0) {
      this.setState({ showCategoryLabel: true });
    } else {
      this.setState({ showCategoryLabel: false });
    }
  }

  render() {
    if (!this.props.gameId || !this.props.isMobile || !this.props.playType)
      return null;

    return (
      <div
        className={`game-action modal ${
          this.props.gameId ? "show" : "d-none"
        } expand`}
        onClick={this.onCloseClickHandler}
      >
        <div
          className="modal-dialog nopadding"
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div className="modal-content">
            <div
              className="modal-body"
              ref={this.modalRef}
              onScroll={this.handleScroll}
            >
              <div className="header-game-action row">
                <div className="col-auto">
                  <PreviousIcon onClick={this.props.history.goBack} />
                </div>
                <div className="col d-flex align-items-center">
                  {this.state.details && (
                    <span
                      className={`details-actions ${
                        this.state.hasPromotionIcons ? "promotional" : ""
                      } ${this.state.showCategoryLabel ? "fade-in" : ""}`}
                      ref={this.actionLabel}
                    >
                      {this.state.details.get("name")}
                    </span>
                  )}
                </div>
                {this.props.showCashier && (
                  <div
                    className="col-auto"
                    onClick={this.props.broadcastCashier}
                  >
                    <CashierIcon />
                  </div>
                )}
                {this.props.showSwitchDevice && (
                  <div
                    className="col-auto"
                    onClick={this.props.broadcastSwitchDevice}
                  >
                    <TransferIcon />
                  </div>
                )}
                <div className="col-auto">
                  <CloseIcon onClick={this.onCloseClickHandler} />
                </div>
              </div>
              <div className="header-game-action d-none">
                {this.state.details && (
                  <span
                    className={`details-actions ${
                      this.state.hasPromotionIcons ? "promotional" : ""
                    } ${this.state.showCategoryLabel ? "fade-in" : ""}`}
                    ref={this.actionLabel}
                  >
                    {this.state.details.get("name")}
                  </span>
                )}
                {this.props.showCashier && (
                  <div
                    className="col-auto"
                    onClick={this.props.broadcastCashier}
                  >
                    <CashierIcon />
                  </div>
                )}
                {this.props.showSwitchDevice && (
                  <div
                    className="col-auto"
                    onClick={this.props.broadcastSwitchDevice}
                  >
                    <TransferIcon />
                  </div>
                )}
                <CloseIcon onClick={this.onCloseClickHandler} />
              </div>
              <div className="image-spacer" />
              <div className="image-row-expand">
                {this.state.details && (
                  <img
                    className="img-fluid w-100"
                    src={`${setDefaultImageFormat(
                      this.state.details.getIn(["images", "bannerUrl"]) +
                        "width=450&theme=dark",
                      this.props.imageFormat.get("banner")
                    )}`}
                    alt={this.state.details.get("name")}
                  />
                )}
                {!this.state.details && (
                  <img
                    className="img-fluid w-100"
                    src={bannerDefault}
                    alt="default"
                  />
                )}
              </div>
              <div className="row">
                <div className="col">
                  <div className="info">
                    <div className="title">
                      {!this.state.details && (
                        <span className="dummy">XXXX XXXXXX</span>
                      )}

                      {this.state.details && this.state.details.get("name") && (
                        <Fragment>
                          {this.state.hasPromotionIcons && (
                            <span className="game-feature">
                              {this.state.details.get("hasFreeRounds") && (
                                <span className="game-feature-badge ribbonIcon">
                                  <RibbonIcon
                                    color="rgba(255, 204, 0, 1)"
                                    selectedColor=""
                                  />
                                </span>
                              )}
                              {this.state.details.get(
                                "partOfOnGoingTournament"
                              ) && (
                                <span className="game-feature-badge trophyIcon">
                                  <TrophyIcon
                                    color="rgba(255, 204, 0, 1)"
                                    selectedColor=""
                                  />
                                </span>
                              )}
                              {this.state.details.get("isJackpot") && (
                                <span className="game-feature-badge jackpotIcon">
                                  <CrownIcon color="rgba(255, 204, 0, 1)" />
                                </span>
                              )}
                              {this.state.details.get("isFeatured") && (
                                <span className="game-feature-badge editorsChoiceIcon">
                                  <EditorsChoiceIcon color="rgba(255, 204, 0, 1)" />
                                </span>
                              )}
                            </span>
                          )}
                          <span>{this.state.details.get("name")}</span>
                        </Fragment>
                      )}
                    </div>
                    <div className="provider">
                      {!this.state.details && (
                        <span className="dummy">XXXXXXXX</span>
                      )}
                      {this.state.details &&
                        this.state.details.get("provider") && (
                          <span>{this.state.details.get("provider")}</span>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  {this.state.isLoading && (
                    <div className="btn btn-secondary w-100 dummy">&nbsp;</div>
                  )}
                  {!this.state.isLoading &&
                    this.state.details &&
                    this.state.realUrl && (
                      <div
                        className="btn btn-danger w-100"
                        onClick={() => {
                          this.onPlayClick("launch.real");

                          let search = queryString.parse(
                            this.props.location.search
                          );
                          if (search.nav && search.playType) {
                            if (search.gameId) {
                              ReactGA.event({
                                category: search.nav,
                                action: "launch.real",
                                label: search.gameId,
                              });
                            }
                          }
                        }}
                      >
                        <span className="play-now-icon">
                          <PlayNow />
                        </span>
                        &nbsp;
                        {Translations.playNow}
                      </div>
                    )}

                  {this.state.isLoading && (
                    <div className="btn btn-secondary w-100 dummy">&nbsp;</div>
                  )}

                  {!this.state.isLoading &&
                    this.state.details &&
                    this.state.demoUrl && (
                      <div
                        className="btn btn-secondary w-100"
                        onClick={() => {
                          this.onPlayClick("launch.demo");

                          let search = queryString.parse(
                            this.props.location.search
                          );
                          if (search.nav && search.playType) {
                            if (search.gameId) {
                              ReactGA.event({
                                category: search.nav,
                                action: "launch.demo",
                                label: search.gameId,
                              });
                            }
                          }
                        }}
                      >
                        <span className="play-now-icon">
                          <PlayDemo />
                        </span>
                        &nbsp;{Translations.demoPlay}
                      </div>
                    )}
                  {!this.state.isLoading &&
                    this.state.details &&
                    !this.state.realUrl &&
                    !this.state.demoUrl && (
                      <div className="no-gameplay">
                        {Translations.noAvailableGameplay}
                      </div>
                    )}
                </div>
              </div>

              <table className={`tinfo`}>
                <tbody>
                  {!this.state.details && (
                    <tr className="description">
                      <td colSpan={2}>
                        <span className="dummy">
                          XXXXXX XX XXXX XXXX XX XXXXXXX XXX XXXXXXX XXXXXXX
                        </span>
                      </td>
                    </tr>
                  )}
                  {this.state.details && (
                    <tr className="description">
                      <td colSpan={2}>
                        {this.state.details.get("description")}
                      </td>
                    </tr>
                  )}
                  {!this.state.details && (
                    <tr className="trinfo">
                      <th>{Translations.type}: </th>
                      <td>
                        <span className="dummy">XXXXXX XX XXXX</span>
                      </td>
                    </tr>
                  )}
                  {this.state.details && this.state.details.get("gameType") && (
                    <tr className="trinfo">
                      <th>{Translations.type}: </th>
                      <td>{this.state.details.get("gameType")}</td>
                    </tr>
                  )}
                  {!this.state.details && (
                    <tr className="trinfo">
                      <th>{Translations.features}: </th>
                      <td>
                        <span className="dummy">XXX XX XXXXX</span>
                      </td>
                    </tr>
                  )}
                  {this.state.details &&
                    this.state.details.get("specialFeatures") && (
                      <tr className="trinfo">
                        <th>{Translations.features}: </th>
                        <td>
                          <span>
                            {this.state.details
                              .get("specialFeatures")
                              .join(", ")}
                          </span>
                        </td>
                      </tr>
                    )}
                  {!this.state.details && (
                    <tr className="trinfo">
                      <th>{Translations.themes}: </th>
                      <td>
                        <span className="dummy">XXXX XXX XXXXXXXX</span>
                      </td>
                    </tr>
                  )}
                  {this.state.details && this.state.details.get("themes") && (
                    <tr className="trinfo">
                      <th>{Translations.themes}: </th>
                      <td>
                        <span>
                          {this.state.details.get("themes").join(", ")}
                        </span>
                      </td>
                    </tr>
                  )}
                  {!this.state.details && (
                    <tr className="trinfo">
                      <th>{Translations.volatility}: </th>
                      <td>
                        <span className="dummy">XXX XX XXXXX</span>
                      </td>
                    </tr>
                  )}
                  {this.state.details && this.state.details.get("volatility") && (
                    <tr className="trinfo">
                      <th>{Translations.volatility}: </th>
                      <td>
                        <span>{this.state.details.get("volatility")}</span>
                      </td>
                    </tr>
                  )}
                  {!this.state.details && (
                    <tr className="trinfo">
                      <th>{Translations.releaseDate}: </th>
                      <td>
                        <span className="dummy">XXX XX XXXXX</span>
                      </td>
                    </tr>
                  )}
                  {this.state.details && this.state.details.get("releaseDate") && (
                    <tr className="trinfo">
                      <th>{Translations.released}: </th>
                      <td>
                        <span>{this.state.details.get("releaseDate")}</span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Fragment>
                <div className="horizontal-bar"> </div>
                {!this.state.relatedLinks && (
                  <Fragment>
                    <CategoryVeritcalList />
                    <CategoryVeritcalList />
                  </Fragment>
                )}
                {this.state.relatedLinks &&
                  this.state.relatedLinks.map((link, key) => {
                    if (
                      this.props.ownCategoryLink &&
                      link.get("rel") === this.props.ownCategoryLink.get("rel")
                    ) {
                      return null;
                    } else if (
                      this.props.ownCategoryGamesTotal === 1 &&
                      this.props.nav === link.get("rel")
                    ) {
                      return null;
                    }

                    return (
                      <CategoryVeritcalList
                        key={key}
                        match={this.props.match}
                        link={link}
                        navigateTo={(path) => {
                          let modalElem = this.modalRef.current;
                          modalElem.scrollTop = 0;
                          this.props.navigateTo(path);
                        }}
                        listItemComponent={CategoryListItem}
                        location={this.props.location}
                      />
                    );
                  })}
              </Fragment>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let sessionId = state.security.get("sessionId");
  let isMobile = state.window.get("isMobile");
  let { gameId, playType, nav } = queryString.parse(ownProps.location.search);
  //app not yet initialized
  if (!sessionId || !isMobile) {
    return {};
  }
  let isRound = false;
  let showCashier;
  let showSwitchDevice;
  let gameLaunchTarget;
  let pType;
  try {
    pType =
      playType === "launch.real" || playType === "launch.demo"
        ? null
        : playType;
  } catch (err) {}
  try {
    gameLaunchTarget = state.links.get("gameLaunchTarget");
  } catch (err) {}
  try {
    isMobile = state.window.get("isMobile");
  } catch (err) {}

  try {
    showCashier = state.profile.get("showCashier");
  } catch (err) {}
  try {
    showSwitchDevice = state.profile.get("showSwitchDevice");
  } catch (err) {}
  try {
    if (
      nav.indexOf("home.recentlyplayed") > -1 ||
      nav.indexOf("play-it-again") > -1
    ) {
      isRound = true;
    }
  } catch (err) {}

  return {
    isMobile,
    isRound,
    gameId,
    gameActionDetails: state.game.get("stateDetails"),
    showCashier,
    showSwitchDevice,
    gameLaunchTarget,
    playType: pType,
    imageFormat: state.window.get("imageFormat"),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(ActionCreator, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return Object.assign({}, stateProps, ownProps, {
    fetchGameDetailsUrl: (gameId) => dispatchProps.fetchGameDetailsUrl(gameId),
    fetchGameDetails: (link, params) =>
      dispatchProps.fetchGameDetails(link, params),
    fetchGameUrl: (link, params) => dispatchProps.fetchGameUrl(link, params),
    fetchRelatedLinks: (route, params) =>
      dispatchProps.fetchRelatedLinks(route, params),
    fetchGames: (route, params) => dispatchProps.fetchGames(route, params),
    navigateTo: (path) => ownProps.history.push(`${path}`),
    showModal: (modal) => dispatchProps.showModal(modal),
    hideModal: (modal) => dispatchProps.hideModal(modal),
    fetchBalance: () => dispatchProps.fetchBalance(),
    clearGameAction: () => dispatchProps.clearGameAction(),
    onGameLaunch: (params) => dispatchProps.onGameLaunch(params),
    broadcastCashier: () => dispatchProps.broadcastCashier(),
    broadcastSwitchDevice: () => dispatchProps.broadcastSwitchDevice(),
  });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(GameAction);
