import React, { Component, Fragment, createRef } from "react";
import { fromJS } from "immutable";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreator } from "actions";
import * as queryString from "query-string";
import { LoadingAnimation } from "component/animation";
import { convertMS } from "utils/date";
import { isEmpty, isEqual } from "lodash";
import { hideShowButton } from "utils/hideShowButton";
import {
  ProviderTypeListItem,
  TournamentTypeListItem,
  JackpotTypeListItem,
} from "component/listItem";
import ReactIdswiper from "react-id-swiper";
import Translations from "translations";

// Version <= 2.3.2
//import 'react-id-swiper/lib/styles/css/swiper.css';
// Version >= 2.4.0
import "swiper/dist/css/swiper.css";

import "containers/CategoryListDesktop/styles.scss";
import { LeftArrowIcon, RightArrowIcon } from "component/icons";
import { CommonHeaderDesktop } from "component/common";
import { CategoryType8Skeleton } from "component/listItemSkeleton";

const swipeParams = {
  slidesPerView: "auto",
  spaceBetween: 25,
  /* loop: true, */
  slidesOffsetBefore: 35,
  //centeredSlides: true,
  centerInsufficientSlides: false,
  shouldSwiperUpdate: true,
  //rebuildOnUpdate: true,
  /* for proper looping on slide group*/
  slidesPerGroup: 6,
  loopFillGroupWithBlank: false,

  breakpoints: {
    576: {
      slidesPerGroup: 2,
    },
    768: {
      slidesPerGroup: 3,
    },
    992: {
      slidesPerGroup: 4,
    },
    1200: {
      slidesPerGroup: 5,
    },
    1400: {
      slidesPerGroup: 6,
    },
  },
  /* end for proper looping */
  /* pagination: {
		el: '.swiper-pagination',
		clickable: true
	}, */
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },

  renderPrevButton: () => (
    <span
      className="swiper-button-prev"
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <LeftArrowIcon
        color="#fff"
        className="left-arrow"
        width={18}
        height={18}
      />
    </span>
  ),
  renderNextButton: () => (
    <span
      className="swiper-button-next"
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <RightArrowIcon
        color="#fff"
        className="right-arrow"
        width={18}
        height={18}
      />
    </span>
  ),
};

const swipeParams2 = {
  slidesPerView: "auto",
  spaceBetween: 25,
  /* loop: true, */
  slidesOffsetBefore: 35,
  //centeredSlides: true,
  centerInsufficientSlides: false,
  shouldSwiperUpdate: true,
  //rebuildOnUpdate: true,
  /* for proper looping on slide group*/
  slidesPerGroup: 4,
  loopFillGroupWithBlank: false,

  breakpoints: {
    576: {
      slidesPerGroup: 1,
    },
    768: {
      slidesPerGroup: 2,
    },
    991: {
      slidesPerGroup: 2,
    },
    1200: {
      slidesPerGroup: 3,
    },
  },
  /* end for proper looping */
  /* pagination: {
		el: '.swiper-pagination',
		clickable: true
	}, */
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },

  renderPrevButton: () => (
    <span
      className="swiper-button-prev"
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <LeftArrowIcon
        color="#fff"
        className="left-arrow"
        width={18}
        height={18}
      />
    </span>
  ),
  renderNextButton: () => (
    <span
      className="swiper-button-next"
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <RightArrowIcon
        color="#fff"
        className="right-arrow"
        width={18}
        height={18}
      />
    </span>
  ),
};

const ACTION_MODAL = "ACTION_MODAL";
class CategoryListDesktop extends Component {
  constructor(props) {
    super(props);
    this.swipeRef = React.createRef();
    this.setSwipeState = this._setSwipeState.bind(this);
    this.onSlideChange = this._onSlideChange.bind(this);
    this.checkReachEnd = this._checkReachEnd.bind(this);
    this.onGameItemClickHandler = this._onGameItemClickHandler.bind(this);
    this.launchActions = this._launchActions.bind(this);
    this.getGames = this._getGames.bind(this);
    this.onShowTournamentDetails = this._onShowTournamentDetails.bind(this);
    this.onSeeAllScrollHandler = this._onSeeAllScrollHandler.bind(this);
    this.getTournamentPreview = this._getTournamentPreview.bind(this);
    this.categoryListRef = createRef();
    this.initLoadDelay = null;
    this.onShowMoreHandler = this._onShowMoreHandler.bind(this);
    this.onShowTournamentDetailsHandler = this._onShowTournamentDetailsHandler.bind(
      this
    );
    this.onShowTournamentResultHandler = this._onShowTournamentResultHandler.bind(
      this
    );
    this.onShowJackpotDetailsHandler = this._onShowJackpotDetailsHandler.bind(
      this
    );
    this.state = {
      isLoading: true,
      isLoadingNext: false,
      loop: null,
      navigation: {
        nextEl: "swiper-button-next",
        prevEl: "swiper-button-prev",
      },
      isSeeAll: false,
      detailsAvailable: false,
      showTournamentDetails: false,
      startsIn: null,
      endsIn: null,
      skeleton: [1, 2, 3, 4, 5, 6, 7, 8],
      showRightPanel: false,
    };
  }

  componentDidMount() {
    if (this.props.link.get("rel") === this.props.showMore) {
      this.getGames(this.props.link);
    }
    this.initObeserver();
    window.requestAnimationFrame(this.checkReachEnd);
  }

  initObeserver() {
    if (
      "IntersectionObserver" in window &&
      "IntersectionObserverEntry" in window &&
      "intersectionRatio" in window.IntersectionObserverEntry.prototype
    ) {
      let observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              if (this.categoryListRef.current && !this.props.games) {
                this.initLoadDelay = setTimeout(() => {
                  this.getGames(this.props.link);
                  this.getTournamentPreview(this.props.links);
                }, 500);
              }
            } else {
              if (this.categoryListRef.current) {
                clearInterval(this.initLoadDelay);
              }
            }
          });
        },
        {
          rootMargin: "100px 0px 100px 0px",
        }
      );
      observer.observe(this.categoryListRef.current);
    } else {
      this.getGames(this.props.link);
      this.getTournamentPreview(this.props.links);
    }
  }

  componentWillUnmount() {
    this.props.clearGames(this.props.link);
    try {
      window.removeEventListener("resize", () =>
        this.setSwipeParams(this.props.items)
      );
      window.removeAnimationFrame(this.checkReachEnd);
    } catch (err) {}
  }

  _getTournamentPreview(links) {
    if (!links) return;

    let link = links.find((link) => {
      return link.get("rel").indexOf("tournament.preview") > -1;
    });
    if (link)
      this.props.fetchTournamentPreview(link, {
        parentRel: this.props.link.get("rel"),
      });
  }

  _getGames(link) {
    if (link) {
      let params = {
        size: 10,
      };
      this.setState({
        isLoading: true,
      });
      this.props
        .fetchGames(link, params)
        .then(() => {
          this.setState({
            isLoading: false,
          });
          this.setSwipeState(this.props.items);
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
      this.setSwipeState(this.props.items);
    }
  }

  _checkReachEnd() {
    let seeAllElem = this.refs["SEE_ALL"];
    let bodyActiveEndY =
      Math.abs(document.body.getBoundingClientRect().top) +
      document.body.getBoundingClientRect().height;
    if (seeAllElem && seeAllElem.getBoundingClientRect()) {
      let seeAllStartY =
        seeAllElem.offsetTop +
        seeAllElem.getBoundingClientRect().height -
        seeAllElem.lastElementChild.getBoundingClientRect().height;
      let seeAllEndY =
        seeAllElem.offsetTop + seeAllElem.getBoundingClientRect().height;
      if (
        bodyActiveEndY >= seeAllStartY &&
        bodyActiveEndY <= seeAllEndY &&
        !this.state.isLoadingNext
      ) {
        let itemsSize = this.props.items.size;
        let { totalCount, page } = this.props.games.toJSON();
        let maxCount = this.props.maxCount;

        if (
          itemsSize === totalCount ||
          itemsSize >= maxCount ||
          this.state.isLoadingNext
        ) {
        } else {
          let params = {
            size: 10,
            page: page + 1,
          };
          this.setState({
            isLoadingNext: true,
          });
          this.props
            .fetchGames(this.props.link, params)
            .then(() => {
              this.setState({
                isLoadingNext: false,
              });
              this.setSwipeState(this.props.items);
            })
            .catch((err) => {
              this.setState({
                isLoading: false,
              });
            });
        }
      }
    }

    if (this.props.tournamentPreview) {
      let startsIn =
        this.props.tournamentPreview.get("startsIn") === 0
          ? null
          : convertMS(
              this.props.tournamentPreview.get("startsIn") -
                new Date().getTime()
            );
      let endsIn =
        this.props.tournamentPreview.get("endsIn") === 0
          ? null
          : convertMS(
              this.props.tournamentPreview.get("endsIn") - new Date().getTime()
            );
      let startsInDHS = startsIn
        ? { d: startsIn.d, h: startsIn.h, m: startsIn.m }
        : null;
      let endsInDHS = endsIn ? { d: endsIn.d, h: endsIn.h, m: endsIn.m } : null;
      let ended =
        this.props.tournamentPreview.get("endsIn") - new Date().getTime() <= 0;
      if (
        !isEqual(startsInDHS, this.state.startsIn) ||
        !isEqual(endsInDHS, this.state.endsIn)
      ) {
        this.setState({
          startsIn: startsInDHS,
          endsIn: ended ? null : endsInDHS,
        });
      }
    }

    window.requestAnimationFrame(this.checkReachEnd);
  }

  _setSwipeState(items) {
    if (!this.swipeRef.current) return;
    let { innerWidth } = window;
    let loop = false;

    switch (this.props.template.get("size")) {
      case "l":
        if (innerWidth >= 1400) {
          loop = items.size > 4;
        } else if (innerWidth >= 576) {
          loop = items.size > 2;
        } else {
          loop = items.size > 1;
        }
        break;
      default:
        if (innerWidth >= 1400) {
          loop = items.size > 6;
        } else if (innerWidth >= 576) {
          loop = items.size > 3;
        } else {
          loop = items.size > 2;
        }
        break;
    }

    if (loop !== this.state.loop) {
      this.setState({
        loop: loop,
      });
    }
  }

  _launchActions(game) {
    let {
      locale,
      sessionId,
      page,
      category,
      subCategory,
    } = this.props.match.params;
    let realLink;
    let demoLink;
    let detailsLink;
    let params;
    let categoryPath = this.props.match.path.split("/")[3];

    if (category) {
      params = {
        returnUrl: `${window.location.protocol}//${window.location.host}/${[
          locale,
          sessionId,
          categoryPath,
          page,
          category,
          subCategory,
        ].join("/")}`,
      };
    } else {
      params = {
        returnUrl: `${window.location.protocol}//${window.location.host}/${[
          locale,
          sessionId,
          categoryPath,
          page,
          subCategory,
        ].join("/")}`,
      };
    }

    this.setState({
      realUrl: null,
      demoUrl: null,
    });

    try {
      detailsLink = game.get("links").find((link) => {
        return link.get("rel") === "launch.real";
      });
      this.setState({
        detailsLink: detailsLink,
      });
    } catch (err) {}

    try {
      realLink = game.get("links").find((link) => {
        return link.get("rel") === "launch.real";
      });
      this.props.fetchGameUrl(realLink, params).then((response) => {
        let realUrl = response.payload.data.url;
        this.setState({
          realUrl: realUrl,
        });
      });
    } catch (err) {}

    try {
      demoLink = game.get("links").find((link) => {
        return link.get("rel") === "launch.demo";
      });
      this.props.fetchGameUrl(demoLink, params).then((response) => {
        let demoUrl = response.payload.data.url;
        this.setState({
          demoUrl: demoUrl,
        });
      });
    } catch (err) {}
    this.props.showModal(ACTION_MODAL);
    this.setState({
      selectedGame: game,
    });
  }

  _onGameItemClickHandler(game, playType) {
    if (this.props.isMultiGame && !this.props.isMobile) {
      let games = fromJS([]);
      let gameItem = fromJS({
        gameId: game.get("gameId"),
        playType,
        nav: this.props.link ? this.props.link.get("rel") : null,
      });
      games = games.push(gameItem);
      games = games.push(null); // for multi game support
      games = games.push(null); // for multi game support
      games = games.push(null); // for multi game support
      let gamesEnc = btoa(encodeURIComponent(JSON.stringify(games.toJSON())));
      let params = {
        games: gamesEnc,
      };
      let newLoc = `${this.props.location.pathname}?${queryString.stringify(
        params
      )}`;

      this.props.navigateTo(newLoc);
      return;
    }

    let params = {
      gameId: game.get("gameId"),
      playType,
      nav: this.props.link ? this.props.link.get("rel") : null,
    };
    let newLoc = `${this.props.location.pathname}?${queryString.stringify(
      params
    )}`;
    this.props.navigateTo(newLoc);
  }

  _onSlideChange() {
    if (!this.props.games || !this.swipeRef.current) {
      return;
    }

    let itemsSize = this.props.items.size;
    let { totalCount, page } = this.props.games.toJSON();
    let maxCount = this.props.maxCount;
    let slidesPerGroup = this.swipeRef.current.swiper.params.slidesPerGroup;
    let activeIndex = this.swipeRef.current.swiper.activeIndex;
    let activeSlideGroup = activeIndex / slidesPerGroup;
    let lastSlidesGroup = itemsSize / slidesPerGroup;

    if (
      itemsSize === totalCount ||
      itemsSize >= maxCount ||
      this.state.isLoadingNext
    ) {
    } else if (activeSlideGroup + 2 >= lastSlidesGroup) {
      let params = {
        size: 10,
        page: page + 1,
      };
      this.setState({
        isLoadingNext: true,
      });
      this.props
        .fetchGames(this.props.link, params)
        .then(() => {
          this.setState({
            isLoadingNext: false,
          });
          this.setSwipeState(this.props.items);
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
          });
        });
    }
  }

  _onSeeAllScrollHandler(event) {
    let { page } = this.props.games.toJSON();

    if (
      (this.props.maxCount && this.props.maxCount === this.props.items.size) ||
      (this.props.games &&
        this.props.games.get("totalPages") === this.props.games.get("page"))
    ) {
      return;
    }

    let positionToload =
      event.target.scrollHeight - event.target.offsetHeight * 2;
    let scrollTop = event.target.scrollTop;

    if (scrollTop > positionToload && !this.props.isLoading) {
      let nextPage = page + 1;
      let params = {
        size: 10,
        page: nextPage,
      };
      this.setState({
        isLoading: true,
      });
      this.props
        .fetchGames(this.props.link, params)
        .then(() => {
          this.setState({
            isLoading: false,
          });
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
          });
        });
    }
  }

  _onShowTournamentDetailsHandler(rel, links, images, providers, tab) {
    let params = {
      showTournamentDetails: rel,
      routes: btoa(encodeURIComponent(JSON.stringify(links.toJSON()))),
      tab: tab,
    };

    if (images) {
      params.images = btoa(encodeURIComponent(JSON.stringify(images.toJSON())));
    }

    if (providers) {
      params.providers = btoa(
        encodeURIComponent(JSON.stringify(providers.toJSON()))
      );
    }

    let newLoc = `${this.props.location.pathname}?${queryString.stringify(
      params
    )}`;
    this.props.navigateTo(newLoc);
  }

  _onShowTournamentResultHandler(rel, route, images, providers) {
    let params = {
      showTournamentResults: rel,
      route: btoa(encodeURIComponent(JSON.stringify(route.toJSON()))),
    };

    if (images) {
      params.images = btoa(encodeURIComponent(JSON.stringify(images.toJSON())));
    }

    if (providers) {
      params.providers = btoa(
        encodeURIComponent(JSON.stringify(providers.toJSON()))
      );
    }

    let newLoc = `${this.props.location.pathname}?${queryString.stringify(
      params
    )}`;
    this.props.navigateTo(newLoc);
  }

  _onShowJackpotDetailsHandler(rel, links, images, providers, isWinner) {
    let params = {
      showJackpotDetails: rel,
      routes: btoa(encodeURIComponent(JSON.stringify(links.toJSON()))),
      isWinnerView: isWinner,
    };

    if (images) {
      params.images = btoa(encodeURIComponent(JSON.stringify(images.toJSON())));
    }

    if (providers) {
      params.providers = btoa(
        encodeURIComponent(JSON.stringify(providers.toJSON()))
      );
    }
    let newLoc = `${this.props.location.pathname}?${queryString.stringify(
      params
    )}`;
    this.props.navigateTo(newLoc);
  }

  _onShowMoreHandler(
    rel,
    route,
    images,
    status,
    subtitle,
    highestJpPoolAmount
  ) {
    let params = {
      showMore: rel,
      route: btoa(encodeURIComponent(JSON.stringify(route.toJSON()))),
      status: status,
      JpSubtitle: subtitle,
      JpAmount: highestJpPoolAmount,
    };

    if (images) {
      params.images = btoa(encodeURIComponent(JSON.stringify(images.toJSON())));
    }

    let newLoc = `${this.props.location.pathname}?${queryString.stringify(
      params
    )}`;
    this.props.navigateTo(newLoc);
  }

  _onShowTournamentDetails(rel, route) {
    let params = {
      showTournamentDetails: rel,
      route: btoa(encodeURIComponent(JSON.stringify(route.toJSON()))),
    };
    let newLoc = `${this.props.location.pathname}?${queryString.stringify(
      params
    )}`;
    this.props.navigateTo(newLoc);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.items !== this.props.items) {
      this.setSwipeState(nextProps.items);
    }

    window.addEventListener("resize", this.setSwipeState(nextProps.items));
  }

  renderGameListItem(game, key) {
    let ComponentListItem = this.props.listItemComponent;
    let linkDemo = game.get("links").find((link) => {
      return link.get("rel") === "launch.demo";
    });
    let linkReal = game.get("links").find((link) => {
      return link.get("rel") === "launch.real";
    });
    let linkDetails = game.get("links").find((link) => {
      return link.get("rel") === "game.details";
    });

    return (
      <ComponentListItem
        key={key}
        game={game}
        linkDemo={linkDemo}
        linkReal={linkReal}
        linkDetails={linkDetails}
        host={this.props.host}
        onClick={(rel) => {
          this.onGameItemClickHandler(game, rel);
        }}
        onShowActionClick={() => {
          this.launchActions(game);
        }}
        currency={this.props.currency}
        symbol={this.props.symbol}
        imageFormat={this.props.imageFormat}
      />
    );
  }

  renderProviderListItem(item, key) {
    //TODO: add checking
    let categoryLink = item.getIn(["links", 0]);
    categoryLink = categoryLink.setIn(
      ["rel"],
      `show.more.${categoryLink.get("rel")}`
    );

    return (
      <ProviderTypeListItem
        className={`template-${this.props.template.get("size")}`}
        key={key}
        game={item}
        isMobile={this.props.isMobile}
        onClick={(rel) => {
          this.onShowMoreHandler(categoryLink.get("rel"), categoryLink);
        }}
        imageFormat={this.props.imageFormat}
      />
    );
  }

  renderJackpotListItem(item, key) {
    let detailsLink = item.get("links").find((link) => {
      return link.get("rel").indexOf("jackpot-details") > -1;
    });
    let gamesLink = item.get("links").find((link) => {
      return link.get("rel").indexOf("jackpot-games") > -1;
    });
    let images = item.get("images");
    let providers = item.get("providers");

    return (
      <JackpotTypeListItem
        key={key}
        game={item}
        className={`template-${this.props.template.get("size")}`}
        isMobile={this.props.isMobile}
        onClick={(rel) => {
          this.onShowJackpotDetailsHandler(
            detailsLink.get("rel"),
            item.get("links"),
            images,
            providers,
            true
          );
        }}
        onViewWinnersClick={() => {
          this.onShowJackpotDetailsHandler(
            detailsLink.get("rel"),
            item.get("links"),
            images,
            providers,
            true
          );
        }}
        onViewGamesClick={() => {
          gamesLink = gamesLink.setIn(["name"], item.get("title"));
          this.onShowJackpotDetailsHandler(
            detailsLink.get("rel"),
            item.get("links"),
            images,
            providers,
            false
          );
        }}
        currency={this.props.currency}
        symbol={this.props.symbol}
        fetchGameTypes={this.props.fetchGameTypes}
        latestBroadcast={this.props.latestBroadcast}
        imageFormat={this.props.imageFormat}
      />
    );
  }

  renderTournamentListItem(item, key) {
    let detailsLink = item.get("links").find((link) => {
      return link.get("rel").indexOf("tournament-promotions") > -1;
    });
    let resultsLink = item.get("links").find((link) => {
      return link.get("rel").indexOf("tournament-leaderboard") > -1;
    });
    let images = item.get("images");
    let providers = item.get("providers");

    return (
      <TournamentTypeListItem
        key={key}
        game={item}
        className={`template-${this.props.template.get("size")}`}
        isMobile={this.props.isMobile}
        onClick={(rel) => {
          let tab;
          resultsLink ? (tab = "results") : (tab = "games");
          this.onShowTournamentDetailsHandler(
            detailsLink.get("rel"),
            item.get("links"),
            images,
            providers,
            tab
          );
        }}
        onShowActionClick={() => {}}
        onViewGamesClick={() => {
          this.onShowTournamentDetailsHandler(
            detailsLink.get("rel"),
            item.get("links"),
            images,
            providers,
            "games"
          );
        }}
        onViewResultsClick={() => {
          this.onShowTournamentDetailsHandler(
            detailsLink.get("rel"),
            item.get("links"),
            images,
            providers,
            "results"
          );
        }}
        currency={this.props.currency}
        symbol={this.props.symbol}
        fetchGameTypes={this.props.fetchGameTypes}
        imageFormat={this.props.imageFormat}
      />
    );
  }

  renderListItem(game, key) {
    const rel = this.props.link.get("rel");
    let tournamentId;
    let jackpotId;
    if (rel.includes("tournaments.")) tournamentId = this.props.link.get("rel");
    if (rel.includes("jackpots.")) jackpotId = this.props.link.get("rel");
    switch (rel) {
      case jackpotId:
      case "jackpots":
        return this.renderJackpotListItem(game, key);
      case tournamentId:
      case "tournaments":
        return this.renderTournamentListItem(game, key);
      case "providers":
      case "new-providers":
      case "popular.providers":
        return this.renderProviderListItem(game, key);
      default:
        return this.renderGameListItem(game, key);
    }
  }

  renderPlaceHolder(template, key) {
    let placeholderType;
    switch (template.get("size")) {
      case "l":
        placeholderType = "placeholder-type-7";
        break;
      case "m":
        placeholderType = null;
        break;
      default:
        placeholderType = "placeholder-type-5";
        break;
    }
    return (
      <CategoryType8Skeleton key={key} placeholderType={placeholderType} />
    );
  }

  render() {
    let swParams = null;
    switch (this.props.template.get("size")) {
      case "l":
        swParams = swipeParams2;
        break;
      default:
        swParams = swipeParams;
        break;
    }

    let isShowMoreVisible = hideShowButton(
      window.innerWidth,
      this.props.items.size,
      this.props.template.get("size")
    );

    return (
      <Fragment>
        <div
          className={`category-list-desktop ${
            this.state.isLoading ? "loading-categories" : ""
          } ${this.state.loop ? "loop" : ""} ${
            !this.state.isLoading && this.props.items.size === 0 ? "d-none" : ""
          } ${isShowMoreVisible ? "" : "c-none"}`}
          onClick={(event) => {
            if (
              isShowMoreVisible &&
              !this.props.link.get("rel").includes("tournaments.")
            ) {
              event.stopPropagation();
              let moreLink = this.props.link;
              moreLink = moreLink.setIn(
                ["rel"],
                `show.more.${moreLink.get("rel")}`
              );
              this.onShowMoreHandler(moreLink.get("rel"), moreLink);
            }
          }}
          ref={this.categoryListRef}
        >
          {!this.state.isLoading && (
            <div className="header">
              <CommonHeaderDesktop
                link={this.props.link}
                tournamentPreview={this.props.tournamentPreview}
                detailsAvailable={this.props.detailsAvailable}
                onInfoClick={() => {
                  let params = {
                    tournamentDetails: this.props.link.get("rel"),
                  };
                  let newLoc = `${
                    this.props.location.pathname
                  }?${queryString.stringify(params)}`;
                  this.props.navigateTo(newLoc);
                }}
                rightPanel={
                  isShowMoreVisible
                    ? () => {
                        return (
                          <div className="col-auto d-flex align-items-center h-100">
                            <button
                              className="btn btn-dark"
                              onClick={(event) => {
                                event.stopPropagation();
                                let moreLink = this.props.link;
                                moreLink = moreLink.setIn(
                                  ["rel"],
                                  `show.more.${moreLink.get("rel")}`
                                );
                                this.onShowMoreHandler(
                                  moreLink.get("rel"),
                                  moreLink
                                );
                              }}
                            >
                              {Translations.showMore}
                            </button>
                          </div>
                        );
                      }
                    : ""
                }
              />
            </div>
          )}
          {this.state.isLoading && (
            <Fragment>
              <div className="placeholder-name">
                <div className="dummy" />
              </div>
              <div className="placeholder-ui">
                <ReactIdswiper>
                  {this.state.skeleton.map((key) => {
                    return this.renderPlaceHolder(this.props.template, key);
                  })}
                </ReactIdswiper>
              </div>
            </Fragment>
          )}
          {!this.state.isLoading && (
            <div className={this.props.items.size > 0 ? "" : "d-none"}>
              <ReactIdswiper
                ref={this.swipeRef}
                {...swParams}
                /* loopFillGroupWithBlank={!this.state.loop}
								loop={this.state.loop} */
                on={{
                  slideChange: this.onSlideChange,
                }}
                allowTouchMove={
                  this.props.link.get("rel").includes("tournaments.")
                    ? false
                    : true
                }
              >
                {this.props.items &&
                  this.props.items.map((game, key) => {
                    return this.renderListItem(game, key);
                  })}
                {this.state.isLoadingNext && (
                  <div className="row justify-content-center align-items-center nopadding loadingAnim">
                    <LoadingAnimation />
                  </div>
                )}
              </ReactIdswiper>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let games = null;
  let isLoading = true;
  let items = fromJS([]);
  let symbols = null;
  let currency = null;
  let swParams = null;
  let links = ownProps.link.get("links");
  let detailsAvailable = null;
  let tournamentPreview = null;
  let { showMore } = queryString.parse(ownProps.location.search);
  let template;
  let latestBroadcast;

  try {
    template = ownProps.link.get("templates").find((template) => {
      return template.get("id") === "myQT";
    });
  } catch (err) {
    template = fromJS({ size: "m" });
  }

  try {
    latestBroadcast = state.serverSentEvent.getIn(["latestBroadcast"]);
  } catch (err) {}

  try {
    games = state.games.getIn([ownProps.link.get("rel"), "data"]);
    isLoading = state.games.getIn([ownProps.link.get("rel"), "isLoading"]);
    items = games.get("items");
    tournamentPreview = state.tournaments.get(ownProps.link.get("rel"));
    detailsAvailable = state.games.getIn([
      ownProps.link.get("rel"),
      "route",
      "links",
    ])
      ? true
      : false;

    switch (template.get("size")) {
      case "l":
        swParams = swipeParams2;
        break;
      default:
        swParams = swipeParams;
        break;
    }
  } catch (err) {}

  if (!isEmpty(state.links.getIn(["symbol"]))) {
    let symbolList = state.links.getIn(["symbol"]);
    symbolList = symbolList.map((symbol) => {
      return String.fromCharCode(symbol);
    });
    symbols = symbolList.toJSON().join("");
  }

  if (!isEmpty(state.links.get("currency"))) {
    currency = state.links.get("currency");
  }

  return {
    detailsAvailable,
    host: state.config.get("host"),
    games: games,
    items: items ? items : fromJS([]),
    currency: currency,
    symbol: symbols,
    isLoading: isLoading,
    isMobile: state.window.get("isMobile"),
    links: links,
    swParams: swParams,
    tournamentPreview: tournamentPreview,
    showMore,
    template,
    latestBroadcast,
    isMultiGame: state.links.get("isMultiGame"),
    imageFormat: state.window.get("imageFormat"),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(ActionCreator, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return Object.assign({}, stateProps, ownProps, {
    fetchGames: (route, params) => dispatchProps.fetchGames(route, params),
    fetchGameUrl: (link, params) => dispatchProps.fetchGameUrl(link, params),
    clearGames: (route) => dispatchProps.clearGames(route),
    fetchTournamentPreview: (link, params) =>
      dispatchProps.fetchTournamentPreview(link, params),
    showModal: (modal) => dispatchProps.showModal(modal),
    hideModal: (modal) => dispatchProps.hideModal(modal),
    fetchGameTypes: (route) => dispatchProps.fetchGameTypes(route),
  });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(CategoryListDesktop);
