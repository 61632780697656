import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreator } from "actions";
import * as queryString from "query-string";
import { CategoryListItem, CategoryType8ListItem } from "component/listItem";
import { CategoryType8Skeleton } from "component/listItemSkeleton";
import { GameAction } from "component/modal";
import { isEmpty } from "lodash";
import ShowMoreFilters from "containers/ShowMoreFilters";
import Translations from "translations";
import "containers/Category/styles.scss";
import { CategoryFiltersComponent } from "component/filters";
import { fromJS } from "immutable";
import { setDefaultImageFormat } from "utils/image";

const CONTAINER_CONTENT = "CONTAINER_CONTENT";

const ACTION_MODAL = "ACTION_MODAL";

class Category extends Component {
  constructor(props) {
    super(props);
    this.calculateItemsPerRow = this._calculateItemsPerRow.bind(this);
    this.onGameItemClickHandler = this._onGameItemClickHandler.bind(this);
    this.onApplyFilterClickHandler = this._onApplyFilterClickHandler.bind(this);
    this.checkIfEnd = this._checkIfEnd.bind(this);
    this.loadGames = this._loadGames.bind(this);
    this.launchActions = this._launchActions.bind(this);
    this.resetPosition = this._resetPosition.bind(this);
    this.state = {
      enabledFilters: null,
      loading: false,
      selectedGame: null,
      realUrl: null,
      demoUrl: null,
      skeleton: [1, 2, 3, 4, 5, 6, 7, 8],
      activeFilter: null,
    };
  }

  _calculateItemsPerRow() {
    try {
      // TODO: investigate why ref CATEGORY_LIST not found error
      let style = window.getComputedStyle(this.refs[CONTAINER_CONTENT]);
      let itemsPerRow = Number(style.getPropertyValue("--items-per-row"));
      if (itemsPerRow !== this.state.itemsPerRow) {
        this.setState({
          itemsPerRow: itemsPerRow,
        });
        //this.setItemsPerRow(this.props.items, itemsPerRow)
      }
    } catch (err) {
      console.log(err);
    }
  }

  _checkIfEnd() {
    if (
      !this.state.loading &&
      this.props.games &&
      this.props.games.get("page") < this.props.games.get("totalPages")
    ) {
      let { scrollY, innerHeight } = window;
      let { scrollHeight } = document.getElementById("root");

      let scrollPosition = innerHeight + scrollY;

      scrollPosition = scrollPosition + 150;

      if (scrollPosition >= scrollHeight && !this.state.loading) {
        this.loadGames(this.props, this.props.games.get("page") + 1);
      }
    }
  }

  _loadGames(props, page) {
    if (!props.gamesRoute) return;

    this.setState({
      loading: true,
    });

    let params = new URLSearchParams();
    params.append("size", 20);
    params.append("page", page);
    if (props.sortBy) {
      params.append("sortBy", props.sortBy);
    }

    if (props.filters) {
      props.filters.forEach((filter) => {
        filter.get("values").forEach((value) => {
          if (value.get("toggled") === true) {
            params.append(filter.get("id"), value.get("id"));
          }
        });
      });
    }
    this.props.fetchGames(props.gamesRoute, params).then(() => {
      this.setState({
        loading: false,
      });
      this.checkIfEnd();
    });
  }

  _resetPosition() {
    let { innerWidth } = window;
    let scrollY = document.getElementById("root").scrollTop;
    if (innerWidth >= 1400) {
      if (scrollY > 200) {
        document.getElementById("root").scrollTop = 200;
      }
    } else if (innerWidth >= 1200) {
      if (scrollY > 150) {
        document.getElementById("root").scrollTop = 150;
      }
    } else if (innerWidth >= 992) {
      if (scrollY > 100) {
        document.getElementById("root").scrollTop = 100;
      }
    } else if (innerWidth >= 768) {
      if (scrollY > 70) {
        document.getElementById("root").scrollTop = 70;
      }
    } else {
      if (scrollY > 75) {
        document.getElementById("root").scrollTop = 75;
      }
    }
  }

  componentDidMount() {
    this.calculateItemsPerRow();
    window.addEventListener("scroll", this.checkIfEnd);
    window.addEventListener("resize", this.calculateItemsPerRow);
  }

  componentWillUnmount() {
    try {
      window.removeEventListener("scroll", this.checkIfEnd);
      window.removeEventListener("resize", this.calculateItemsPerRow);
    } catch (err) {}
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.filtersIsLoading !== this.props.filtersIsLoading &&
      nextProps.filtersIsLoading === false &&
      this.props.filters
    ) {
      this.loadGames(nextProps, 1);
    }

    if (
      nextProps.gamesRoute &&
      nextProps.gamesRoute !== this.props.gamesRoute
    ) {
      //this.loadGames(nextProps, 1);
    }

    if (nextProps.sorting !== this.props.sorting && nextProps.sorting) {
      this.loadGames(nextProps, 1);
    }

    if (nextProps.sortBy !== this.props.sortBy && nextProps.sortBy) {
      this.loadGames(nextProps, 1);
    }

    if (
      nextProps.params &&
      nextProps.params.category !== this.props.params.category
    ) {
      //this.resetPosition();
    }

    if (
      nextProps.games &&
      nextProps.games !== this.props.games &&
      nextProps.games.get("page") === 1
    ) {
      //this.resetPosition();
    }
  }

  _onGameItemClickHandler(game, rel) {
    if (this.props.isMultiGame && !this.props.isMobile) {
      let games = fromJS([]);
      let gameItem = fromJS({
        gameId: game.get("gameId"),
        playType: rel,
        nav: this.props.link ? this.props.link.get("rel") : null,
      });
      games = games.push(gameItem);
      games = games.push(null); // for multi game support
      games = games.push(null); // for multi game support
      games = games.push(null); // for multi game support
      let gamesEnc = btoa(encodeURIComponent(JSON.stringify(games.toJSON())));
      let params = {
        games: gamesEnc,
      };
      let newLoc = `${this.props.location.pathname}?${queryString.stringify(
        params
      )}`;

      this.props.navigateTo(newLoc);
      return;
    }

    if (!this.props.isMobile) {
      let params = {
        gameId: game.get("gameId"),
        playType: rel,
        nav: this.props.gamesRoute.get("rel"),
      };
      let newLoc = `${this.props.location.pathname}?${queryString.stringify(
        params
      )}`;
      this.props.navigateTo(newLoc);
    } else {
      let params = {
        playType: rel,
        nav: this.props.gamesRoute.get("rel"),
        gameId: game.get("gameId"),
      };
      this.props.setGameAction(params);
    }
  }

  renderSortTitle(sortBy) {
    let sortTitle;
    switch (sortBy) {
      case "editor":
        sortTitle = Translations.sortEditor;
        break;
      case "popularity":
        sortTitle = Translations.sortPopularity;
        break;
      case "newest":
        sortTitle = Translations.sortNewest;
        break;
      case "name":
        sortTitle = Translations.sortName;
        break;
      case "lastPlayed":
        sortTitle = Translations.sortLastPlayed;
        break;
      case "relevance":
        sortTitle = Translations.sortedRelevance;
        break;
      default:
        sortTitle = "Default";
        break;
    }

    if (sortTitle.includes("並べ替え") || sortTitle.includes("排序")) {
      return (
        <span className="sorted-by">
          &#8226;
          <span
            className="sort-btn"
            onClick={(event) => {
              event.stopPropagation();
              this.setState({
                activeFilter: "sortBy",
              });
            }}
          >
            {sortTitle}
          </span>
        </span>
      );
    } else {
      return (
        <span className="sorted-by">
          &#8226; {"Sorted by"}
          <span
            className="sort-btn"
            onClick={(event) => {
              event.stopPropagation();
              this.setState({
                activeFilter: "sortBy",
              });
            }}
          >
            {sortTitle
              .split("by")
              .pop()
              .trim()}
          </span>
        </span>
      );
    }
  }

  _launchActions(game) {
    let { locale, sessionId, page, category } = this.props.match.params;
    let realLink;
    let demoLink;
    let params = {
      returnUrl: `${window.location.protocol}//${window.location.host}/${[
        locale,
        sessionId,
        "games",
        page,
        category,
      ].join("/")}`,
    };

    this.setState({
      realUrl: null,
      demoUrl: null,
    });

    try {
      realLink = game.get("links").find((link) => {
        return link.get("rel") === "launch.real";
      });
      this.props.fetchGameUrl(realLink, params).then((response) => {
        let realUrl = response.payload.data.url;
        this.setState({
          realUrl: realUrl,
        });
      });
    } catch (err) {}

    try {
      demoLink = game.get("links").find((link) => {
        return link.get("rel") === "launch.demo";
      });
      this.props.fetchGameUrl(demoLink, params).then((response) => {
        let demoUrl = response.payload.data.url;
        this.setState({
          demoUrl: demoUrl,
        });
      });
    } catch (err) {}
    this.props.showModal(ACTION_MODAL);
    this.setState({
      selectedGame: game,
    });
  }

  _onApplyFilterClickHandler() {
    this.props.showFilters(!this.props.filtersVisible);
  }

  render() {
    let ListItem = this.props.isMobile
      ? CategoryListItem
      : CategoryType8ListItem;
    let ListItemSkeleton = CategoryType8Skeleton;
    return (
      <div
        className={`contentContainer ${
          this.props.filtersVisible ? "filtersVisible" : ""
        }`}
        ref={CONTAINER_CONTENT}
      >
        {this.state.selectedGame && (
          <GameAction
            isMobile={this.props.isMobile}
            game={this.state.selectedGame}
            sizeSuffix={this.props.sizeSuffix}
            imgSrc={`${setDefaultImageFormat(
              this.state.selectedGame.getIn(["images", "bannerUrl"]) +
                "&width=180&theme=dark",
              this.props.imageFormat.get("banner")
            )}`}
            realUrl={this.state.realUrl}
            demoUrl={this.state.demoUrl}
            onClose={() => {
              this.props.hideModal(ACTION_MODAL);
              this.setState({
                selectedGame: null,
              });
            }}
            onClick={(rel) => {
              this.props.hideModal(ACTION_MODAL);
              this.onGameItemClickHandler(this.state.selectedGame, rel);
              this.setState({
                selectedGame: null,
              });
            }}
          />
        )}
        <div
          className={`category-container ${
            this.props.isMobile ? "mobile" : ""
          } list`}
        >
          {this.props.filters && (
            <CategoryFiltersComponent
              filters={this.props.filters}
              filterRoute={this.props.filtersLink}
              onApplyFilterClickHandler={(filter) => {
                this.onApplyFilterClickHandler();
                this.setState({
                  activeFilter: filter.get("id"),
                });
              }}
              onSortClick={() => {
                this.onApplyFilterClickHandler();
                this.setState({
                  activeFilter: "sortBy",
                });
              }}
              onFilterClickHandler={this.props.setActiveFilter}
              showSelectedFilter={false}
              games={this.props.games}
              link={this.props.link}
              sortBy={this.props.sortBy}
              enableClearAll={this.props.enableClearAll}
              clearAllFilters={this.props.clearAllFilters}
              isMobile={this.props.isMobile}
              sortingSize={this.props.sortingSize}
            />
          )}
          <div className="row">
            <div
              className={`col ${this.props.filtersVisible ? "col-filter" : ""}`}
            >
              <div className="itemCollection row">
                {this.props.games &&
                  this.props.games.get("items").map((game, key) => {
                    let linkDemo = game.get("links").find((link) => {
                      return link.get("rel") === "launch.demo";
                    });
                    let linkReal = game.get("links").find((link) => {
                      return link.get("rel") === "launch.real";
                    });
                    let linkDetails = game.get("links").find((link) => {
                      return link.get("rel") === "game.details";
                    });

                    return (
                      <div
                        className={`col col-item ${
                          !this.props.isMobile && this.props.filtersVisible
                            ? "filtersVisible"
                            : ""
                        }`}
                        key={key}
                      >
                        <ListItem
                          game={game}
                          linkDemo={linkDemo}
                          linkReal={linkReal}
                          linkDetails={linkDetails}
                          host={this.props.host}
                          key={key}
                          isMobile={this.props.isMobile}
                          onClick={(rel) => {
                            this.onGameItemClickHandler(game, rel);
                          }}
                          onShowActionClick={() => {
                            this.launchActions(game);
                          }}
                          currency={this.props.currency}
                          symbol={this.props.symbol}
                          imageFormat={this.props.imageFormat}
                        />
                      </div>
                    );
                  })}
                {this.state.loading &&
                  this.state.skeleton.map((key) => {
                    return (
                      <div
                        className={`col col-item ${
                          this.props.filtersVisible ? "filtersVisible" : ""
                        }`}
                        key={key}
                      >
                        <ListItemSkeleton />
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <ShowMoreFilters
          activeFilter={this.state.activeFilter}
          filters={this.props.filters}
          filtersLink={this.props.filtersLink}
          sorts={this.props.sorts}
          games={this.props.games}
          onClose={() => {
            this.setState({
              activeFilter: null,
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let gamesRoute;
  let games;
  let filters;
  let filtersIsLoading;
  let filtersLink;
  let sortBy;
  let sorts;
  let enableClearAll;
  let sortingSize = 0;

  if (state.pages) {
    let contents = state.pages
      .get("wl-pages")
      .find((page) => {
        return page.get("rel") === "webLobbyMain";
      })
      .get("content");
    let content = contents.find(
      (content) => content.get("rel") === ownProps.match.params.page
    );
    let links = content.get("links");
    filtersLink = links.get(0);
    gamesRoute = links.get(1);

    if (state.games.get(gamesRoute.get("rel"))) {
      games = state.games.getIn([gamesRoute.get("rel"), "data"]);
    }
  }

  try {
    sortBy = state.filters.getIn([filtersLink.get("rel"), "data", "sortBy"]);
  } catch (err) {}

  try {
    sorts = state.filters.getIn([filtersLink.get("rel"), "data", "sorts"]);
  } catch (err) {}

  try {
    filters = state.filters.getIn([filtersLink.get("rel"), "data", "filters"]);
  } catch (err) {}

  try {
    let toggledSize = 0;
    for (var i = 0; filters.size > i; i++) {
      toggledSize = filters.getIn([i, "toggledCount"]);
      sortingSize = sortingSize + toggledSize;
    }
  } catch (err) {}

  try {
    filtersIsLoading = state.filters.getIn([
      filtersLink.get("rel"),
      "isLoading",
    ]);
  } catch (err) {}

  try {
    enableClearAll = state.filters.getIn([
      filtersLink.get("rel"),
      "enableClearAll",
    ]);
  } catch (err) {}

  let symbols = null;
  if (!isEmpty(state.user.getIn(["symbol"]))) {
    let symbolList = state.user.getIn(["symbol"]);
    symbolList = symbolList.map((symbol) => {
      return String.fromCharCode(symbol);
    });
    symbols = symbolList.toJSON().join("");
  }

  return {
    filtersLink,
    gameDefaultAction: state.links.get("gameDefaultAction")
      ? state.links.get("gameDefaultAction")
      : null,
    isMobile: state.window.get("isMobile"),
    sorting: state.config.get("sorting"),
    host: state.config.get("host"),
    gamesRoute: gamesRoute,
    link: gamesRoute,
    filtersIsLoading: filtersIsLoading,
    filtersVisible: state.config.get("filtersVisible"),
    games: games,
    filters: filters,
    params: ownProps.match.params,
    modals: state.window.get("modals"),
    currency: state.user.get("currency"),
    symbol: symbols,
    sortBy,
    sorts,
    sortingSize,
    enableClearAll,
    isMultiGame: state.links.get("isMultiGame"),
    imageFormat: state.window.get("imageFormat"),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(ActionCreator, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return Object.assign({}, stateProps, ownProps, {
    setActiveFilter: (category, subCategory, filter) =>
      dispatchProps.setActiveFilter(category, subCategory, filter),
    clearFilterByCategory: (category, subcategory) =>
      dispatchProps.clearFilterByCategory(category, subcategory),
    setGameAction: (params) => dispatchProps.setGameAction(params),
    navigateTo: (path) => ownProps.history.push(path),
    fetchGames: (route, params) => dispatchProps.fetchGames(route, params),
    logout: () => dispatchProps.logout(),
    showFilters: (bol) => dispatchProps.showFilters(bol),
    showModal: (modal) => dispatchProps.showModal(modal),
    hideModal: (modal) => dispatchProps.hideModal(modal),
    fetchGameUrl: (link, params) => dispatchProps.fetchGameUrl(link, params),
    clearAllFilters: (category) => dispatchProps.clearAllFilters(category),
  });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Category);
