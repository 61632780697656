import React, { Component, Fragment, createRef } from "react";
import { connect } from "react-redux";
import { fromJS } from "immutable";
import { bindActionCreators } from "redux";
import { ActionCreator } from "actions";
import * as queryString from "query-string";
import Translations from "translations";
import { Route } from "react-router-dom";
import {
  CategoryType4ListItem,
  CategoryType8ListItem,
} from "component/listItem";
import { CategoryType8Skeleton } from "component/listItemSkeleton";
import { ReactComponent as BackArrow } from "assets/icons/previous.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ReactComponent as CloseMark } from "assets/icons/close-mark.svg";
import { SearchIcon } from "component/icons";
import SearchQuick from "containers/SearchQuick";

import "containers/Search/styles.scss";
const SEARCH_MODAL = "SEARCH_MODAL";

class Search extends Component {
  constructor(props) {
    super(props);
    this.searchModal = createRef();
    this.searchInput = createRef();
    this.hideSearchModal = this._hideSearchModal.bind(this);
    this.searchDelay = this._searchDelay.bind(this);
    this.onItemClick = this._onItemClick.bind(this);
    this.onEraseBtnClick = this._onEraseBtnClick.bind(this);
    this.checkIfEnd = this._checkIfEnd.bind(this);
    this.setSearchQuick = this._setSearchQuick.bind(this);
    this.onFocusHandler = this._onFocusHandler.bind(this);
    this.onCloseClickHandler = this._onCloseClickHandler.bind(this);

    this.state = {
      filter: "",
      result: null,
      delay: null,
      loading: false,
      loadingNext: false,
      skeleton: [1, 2, 3, 4, 5, 6, 7, 8],
      displayNext: false,
      searchQuickLinks: null,
      onTextInutOnFocus: false,
      enableExit: true,
    };
  }

  _hideSearchModal = () => {
    let search = queryString.parse(this.props.location.search);

    if (search.q !== "") {
      search.q = "";
      this.setState({ enableExit: true });
    } else if (this.state.enableExit) {
      delete search.q;
      delete search.searchQuick;
      this.setState({ enableExit: true });
    } else {
      this.setState({ enableExit: true });
    }

    this.setState({
      filter: "",
      result: null,
    });

    let newLoc = `${this.props.location.pathname}?${queryString.stringify(
      search
    )}`;
    this.props.navigateTo(newLoc);
    this.onFocusHandler(false);
  };

  _onCloseClickHandler() {
    this.props.navigateTo(this.props.location.pathname);
  }

  handleChange = (event) => {
    let search = queryString.parse(this.props.location.search);
    search.q = event.target.value;
    let newLoc = `${this.props.location.pathname}?${queryString.stringify(
      search
    )}`;
    this.setState({ filter: search.q });

    //add delay
    this.props.navigateTo(newLoc);
  };

  _onItemClick(game, rel) {
    if (!this.props.isMobile) {
      let params = {
        nav: "search.games",
        gameId: game.get("gameId"),
        playType: rel,
        q: this.props.q,
      };

      let newLoc = `${this.props.location.pathname}?${queryString.stringify(
        params
      )}`;
      this.props.navigateTo(newLoc);
    } else {
      let params = {
        playType: rel,
        nav: "search.games",
        gameId: game.get("gameId"),
        isMobile: this.props.isMobile,
      };
      this.props.setGameAction(params);
    }
  }

  onShowActionItemClick = (game) => {
    this.props.onShowActionItemClick(game);
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.showSearch !== nextProps.showSearch) {
      //why reverse
      if (this.props.showSearch) {
        //this.searchInput.setUnFocus();
        this.props.hideModal(SEARCH_MODAL);
        this.setState({ displayNext: false });
      } else {
        this.props.showModal(SEARCH_MODAL);
      }
    }

    if (
      this.props.q !== nextProps.q &&
      nextProps.q === "" &&
      this.props.q === undefined &&
      !this.props.searchQuickLinks
    ) {
      //TODO : Ugly improve condition for destop and mobile view
      setTimeout(() => {
        this.searchInput.current.focus();
      }, 100);
    }
    if (this.props.q !== nextProps.q && nextProps.q) {
      clearTimeout(this.state.delay);
      let params = {
        q: nextProps.q,
        size: 25,
        page: 1,
      };
      let delay = setTimeout(this.searchDelay, 1000, params);
      this.setState({
        delay: delay,
      });
    }
  }

  _searchDelay(q) {
    if (q.page === 1) {
      this.setState({
        loading: true,
      });
    } else {
      this.setState({
        loadingNext: true,
      });
    }
    this.props.search(q).then((response) => {
      let result;
      if (response.page === 1) {
        result = fromJS(response);
      } else {
        let resp = fromJS(response);
        let mergeItems = resp.setIn(
          ["items"],
          this.state.result.getIn(["items"]).concat(resp.getIn(["items"]))
        );
        result = this.state.result.merge(mergeItems);
      }
      this.setState({
        result,
        loading: false,
        loadingNext: false,
      });
    });
  }

  _onEraseBtnClick() {
    this.searchModal.current.scrollTop = 0;

    let search = queryString.parse(this.props.location.search);
    search.q = "";
    let newLoc = `${this.props.location.pathname}?${queryString.stringify(
      search
    )}`;
    this.setState({
      filter: "",
      result: null,
    });
    this.onFocusHandler(true);
    this.searchInput.current.focus();
    this.props.navigateTo(newLoc);
  }

  renderSearchListItem(game, key, arr) {
    const isLastElement = arr.size - 1 === key;
    let linkDemo = game.get("links").find((link) => {
      return link.get("rel") === "launch.demo";
    });
    let linkReal = game.get("links").find((link) => {
      return link.get("rel") === "launch.real";
    });
    let linkDetails = game.get("links").find((link) => {
      return link.get("rel") === "game.details";
    });
    let ListItem;

    this.props.isMobile
      ? (ListItem = CategoryType4ListItem)
      : (ListItem = CategoryType8ListItem);

    return (
      <>
        <ListItem
          filter={this.state.filter}
          key={key}
          game={game}
          linkDemo={linkDemo}
          linkReal={linkReal}
          linkDetails={linkDetails}
          isMobile={this.props.isMobile}
          onClick={(rel) => {
            this.onItemClick(game, rel);
            this.setState({ displayNext: true });
          }}
          onShowActionClick={() => {
            this.onShowActionItemClick(game);
          }}
          currency={this.props.currency}
          symbol={this.props.symbol}
          imageFormat={this.props.imageFormat}
        />
        {!isLastElement && this.props.isMobile && (
          <div className="row line">
            <div className={`${"col-5"}`} />
            <div className={`${"col-7"}`}>
              <div className="c-line" />
            </div>
          </div>
        )}
      </>
    );
  }

  _checkIfEnd() {
    if (!this.state.result) {
      return;
    }

    let { page, totalPages } = this.state.result.toJSON();
    //this.searchInput.setUnFocus();

    if (!this.state.loading && !this.state.loadingNext && page < totalPages) {
      let { scrollHeight, offsetHeight, scrollTop } = this.searchModal.current;
      let scrollPosition = offsetHeight + scrollTop + 150;

      if (scrollPosition >= scrollHeight) {
        let params = {
          q: this.props.q,
          size: 25,
          page: page + 1,
        };
        this.searchDelay(params);
      }
    }
    this.searchInput.current.blur();
  }

  componentDidMount() {
    this.searchModal.current.addEventListener("scroll", this.checkIfEnd);
  }

  componentWillUnmount() {
    try {
      this.searchModal.current.removeEventListener("scroll", this.checkIfEnd);
    } catch (err) {}
  }

  renderResultPlaceholder(key) {
    return this.props.isMobile ? (
      <div key={key} className={`col`}>
        <div className="row placeholder-ui">
          <div className="image col-6">
            <CategoryType8Skeleton />
          </div>
          <div className="col-6">
            <div className="placeholder-details">
              <div className="name">
                <div className="dummy" />
              </div>
              <div className="provider">
                <div className="dummy" />
              </div>
              <div className="game-type">
                <div className="dummy" />
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div key={key} className={`col`}>
        {" "}
        <CategoryType8Skeleton />{" "}
      </div>
    );
  }

  _setSearchQuick(value) {
    let search = queryString.parse(this.props.location.search);
    search.searchQuick = value;
    let newLoc = `${this.props.location.pathname}?${queryString.stringify(
      search
    )}`;
    this.setState({
      filter: "",
      result: null,
    });
    this.props.navigateTo(newLoc);
  }

  _onFocusHandler(bol) {
    this.setState({
      onTextInutOnFocus: bol,
    });
  }

  checkBackgroundScroll() {
    const root = document.getElementById("root");
    if (this.props.showSearch) {
      root.style.cssText = `
            position: fixed;
            height: 100%;
            overflow: hidden;
            `;
    } else {
      root.style.cssText = `
            overflow-y: scroll;
            height: auto%;
            `;
    }
  }

  render() {
    const {
      filter,
      loading,
      loadingNext,
      result,
      onTextInutOnFocus,
    } = this.state;
    const { isMobile, locale } = this.props;
    const isResultRendering =
      !loadingNext && !loading && result && result.get("totalCount");

    if (isMobile) {
      this.checkBackgroundScroll();
    }

    return (
      <div
        className={`search-modal ${
          isMobile ? "search-modal-mobile" : "search-modal-desktop"
        } ${this.props.showSearch ? "active" : "inactive"}
                ${
                  onTextInutOnFocus &&
                  (isResultRendering === 0 || isResultRendering <= 3)
                    ? "no-action"
                    : ""
                }
            `}
        ref={this.searchModal}
      >
        <div className="header search-modal-header">
          <div className="row">
            {
              <div className="col-auto nopadding">
                <span
                  className="back-arrow close-btn"
                  onClick={this.hideSearchModal}
                >
                  <BackArrow width="34" />
                </span>
              </div>
            }
            <div
              className={`col form-group ${
                this.state.onTextInutOnFocus ? "focus" : ""
              } nopadding has-search`}
            >
              <div className="row nopadding d-flex align-items-center">
                <div className="col-auto nopadding">
                  <SearchIcon className={"search-modal-icon"} />
                </div>
                <div className="col nopadding">
                  <input
                    ref={this.searchInput}
                    type="text"
                    className={`form-control ${
                      this.state.onTextInutOnFocus ? "focus" : ""
                    }`}
                    placeholder={`${
                      this.state.onTextInutOnFocus
                        ? Translations.search
                        : Translations.whatAreYouLookingFor
                    }`}
                    value={filter}
                    onTouchStart={() => {
                      if (document.activeElement !== this.searchInput.current) {
                        this.searchInput.current.focus();
                      }
                    }}
                    onClick={() => {
                      if (document.activeElement !== this.searchInput.current) {
                        this.searchInput.current.focus();
                      }
                      if (onTextInutOnFocus) {
                        this.setState({ enableExit: false });
                      }
                    }}
                    onChange={this.handleChange}
                    onFocus={() => {
                      this.onFocusHandler(true);
                    }}
                    onBlur={() => {
                      let elem = this.searchInput.current;
                      this.onFocusHandler(elem.value.length > 0 ? true : false);
                    }}
                  />
                </div>
                {this.state.filter !== "" && (
                  <div
                    onClick={this.onEraseBtnClick}
                    className="col-auto nopadding erase-btn clear"
                  >
                    <CloseMark />
                  </div>
                )}
              </div>
            </div>
            {
              <div className="col-auto">
                <span
                  className="close-modal"
                  onClick={this.onCloseClickHandler}
                >
                  <CloseIcon width="34" />
                </span>
              </div>
            }
          </div>
        </div>
        <div className="content">
          {!loadingNext &&
            filter.length >= 3 &&
            !loading &&
            this.state.result &&
            this.state.result.get("totalCount") > 0 && (
              <div className="content-result-count">
                {Translations.found} {this.state.result.get("totalCount")}{" "}
                {Translations.gamesWith}
                <span className="games"> {filter}</span>
              </div>
            )}
          {filter.length > 2 && loading && (
            <Fragment>
              <div className="placeholder-ui">
                <div className="filter-count">
                  <div className="dummy" />
                </div>
              </div>
            </Fragment>
          )}
          <div className="row">
            {!loading &&
              this.state.result &&
              this.state.result.get("items").map((game, key, arr) => {
                if (locale === "en_US" && filter.length <= 2) return null;

                return (
                  <div className={`col`} key={key}>
                    {this.renderSearchListItem(game, key, arr)}
                  </div>
                );
              })}
            {(loading || loadingNext) && (
              <Fragment>
                {this.state.skeleton.map((key) => {
                  return this.renderResultPlaceholder(key);
                })}
              </Fragment>
            )}
          </div>
          {!loadingNext &&
            !loading &&
            this.state.onTextInutOnFocus &&
            this.props.q !== undefined &&
            locale !== "ja_JP" &&
            this.props.q.length <= 2 &&
            locale !== "zh_CN" && (
              <Fragment>
                <div className="keyword-message text-center">
                  {Translations.searchMessage1}
                </div>
                <div className="keyword-message text-center">
                  {Translations.searchMessage2}
                </div>
              </Fragment>
            )}
          {!loadingNext &&
            !loading &&
            this.state.onTextInutOnFocus &&
            this.props.q !== undefined &&
            this.props.q.length < 1 &&
            (locale === "zh_CN" || locale === "ja_JP") && (
              <Fragment>
                <div className="keyword-message text-center">
                  {Translations.searchMessage1}
                </div>
              </Fragment>
            )}
          {!loadingNext &&
            !loading &&
            this.state.result &&
            this.props.q !== undefined &&
            ((this.props.q.length >= 1 && locale === "zh_CN") ||
              (this.props.q.length >= 3 && locale !== "zh_CN") ||
              (this.props.q.length >= 1 && locale === "ja_JP")) &&
            (this.props.q !== undefined &&
              this.state.result.get("totalCount") === 0 &&
              this.props.q !== "") && (
              <div className="search-modal-content-no-result">
                {locale === "ja_JP" ? (
                  <div>
                    <span className="search-modal-content-no-result-keyword">
                      {" "}
                      {this.props.q}
                    </span>
                    {Translations.noResultsFor}
                  </div>
                ) : (
                  <div>
                    {Translations.noResultsFor}
                    <span> {this.props.q}</span>
                  </div>
                )}
              </div>
            )}
          <Route
            path="*"
            render={(props) => (
              <SearchQuick {...props} show={!this.state.onTextInutOnFocus} />
            )}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let { q, searchQuick } = queryString.parse(ownProps.location.search);
  let searchQuickLinks = state.links.get("searchQuickLinks");
  let quickLinks = state.search.get("quickLinks");
  let quickLink;
  try {
    quickLink =
      searchQuick !== undefined
        ? quickLinks.find((link) => {
            return link.get("rel") === searchQuick;
          })
        : quickLinks.get(0);
  } catch (err) {}

  return {
    gameDefaultAction: state.links.get("gameDefaultAction")
      ? state.links.get("gameDefaultAction")
      : null,
    q,
    searchQuick,
    showSearch: q !== undefined,
    searchQuickLinks,
    quickLink,
    locale: state.config.getIn(["locale"]),
    isMobile: state.window.get("isMobile"),
    imageFormat: state.window.get("imageFormat"),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(ActionCreator, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return Object.assign({}, stateProps, ownProps, {
    setGameAction: (params) => dispatchProps.setGameAction(params),
    setSearchVisibility: (value) => dispatchProps.setSearchVisibility(value),
    navigateTo: (path) => ownProps.history.push(path),
    showModal: (modal) => dispatchProps.showModal(modal),
    hideModal: (modal) => dispatchProps.hideModal(modal),
    search: (value) => dispatchProps.search(value),
    fetchSearchQuickLinks: (route) =>
      dispatchProps.fetchSearchQuickLinks(route),
    setSearchQuickLinks: (data) => dispatchProps.setSearchQuickLinks(data),
    fetchSearchQuick: (route, params) =>
      dispatchProps.fetchSearchQuick(route, params),
  });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Search);
