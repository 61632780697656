import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreator } from "actions";
import { currencyFormatter } from "utils/currencyHelper";
import "containers/Navigation/styles.scss";
import Translations from "translations";
import { isEmpty } from "lodash";
import * as queryString from "query-string";
import { UserProfileIcon } from "component/icons";
import { SearchIcon } from "component/icons";

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.setSearchVisibility = this._setSearchVisibility.bind(this);
    this.checkBalanceVisibility = this._checkBalanceVisibility.bind(this);

    this.state = {
      searchClass: "col-7",
      expandBalance: true,
    };
  }

  _setSearchVisibility = () => {
    let search = queryString.parse(this.props.location.search);
    search.q = "";
    let newLoc = `${this.props.location.pathname}?${queryString.stringify(
      search
    )}`;
    this.props.setSearchVisibility(!this.props.showSearch);
    this.props.navigateTo(newLoc);
  };

  _checkBalanceVisibility(props) {
    if (
      this.props.displaySearch ||
      (this.props.isMobile && window.innerWidth > 576) ||
      !window.matchMedia("(orientation: portrait)").matches
    ) {
      this.setState({ expandBalance: true });
    } else {
      this.setState({ expandBalance: false });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.q !== nextProps.q && nextProps.q) {
    }

    const isPortrait = window.matchMedia("(orientation: portrait)").matches;

    if (this.props.isMobile) {
      if (!nextProps.displaySearch && nextProps.showMenu) {
        setTimeout(() => this.setState({ expandBalance: false }), 5000);
      } else if (
        nextProps.displaySearch ||
        !nextProps.showMenu ||
        !isPortrait
      ) {
        this.setState({ expandBalance: true });
      } else {
        setTimeout(() => this.setState({ expandBalance: false }), 5000);
      }
    }
  }

  componentDidMount() {
    window.addEventListener("orientationchange", (event) => {
      window.requestAnimationFrame(this.checkBalanceVisibility);
    });
  }

  handleBalanceClick = () => {
    if (this.state.expandBalance) {
      this.setState({ expandBalance: false });
    } else {
      this.setState({ expandBalance: true });
      setTimeout(() => this.setState({ expandBalance: false }), 5000);
    }
  };

  render() {
    const { isMobile, balance, showMenu, filtersVisible, page } = this.props;
    const isSearchToggled = !this.props.displaySearch && this.props.page;
    const isPortrait = window.matchMedia("(orientation: portrait)").matches;

    return (
      <div
        className={`navigation container-fluid
        ${showMenu ? "with-menu" : ""}
        ${filtersVisible ? "filter-visible" : ""}
        ${!isMobile ? "navigation-desktop" : ""}`}
      >
        <div className="row">
          {filtersVisible && !isMobile && <div className="nav-spacer" />}
          <div className="col col-header">
            {page && <span>{page.get("name")}</span>}
          </div>
          {!isPortrait &&
            <div className="col">
            {isSearchToggled && (
              <div
                className="search-bar input-group input-group-m float-right"
                onClick={this.setSearchVisibility}
                style={{maxWidth: Translations.searchPlaceHolder.length+204}}
              >
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <SearchIcon className="search-icon" />
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder={`${Translations.searchPlaceHolder}`}
                  readOnly
                />
              </div>
            )}
            </div>
          }
          <div className={`${!isPortrait ? "col-auto col-side user-icons" : "col"}`}>
              {showMenu && (
                <span className="profile-icon-holder float-right">
                  <UserProfileIcon
                    onClick={() => {
                      this.props.setMenuVisibility(this.props.showMenu);
                    }}
                    className={"profile-icon"}
                  />
                </span>
              )}

            {page && balance && this.state.expandBalance && (
              <span
                className="currency-holder float-right"
                onClick={isMobile ? this.handleBalanceClick : undefined}
              >
                {currencyFormatter(balance, {
                  currency: this.props.currency,
                  symbol: this.props.symbol,
                  decimalplaces: this.props.decimalPlaces,
                })}
              </span>
            )}
            {page && balance && !this.state.expandBalance && (
              <span
                className="symbol-holder float-right"
                onClick={this.handleBalanceClick}
              >
                {this.props.symbol}
              </span>
            )}
            {isPortrait && isSearchToggled && (
              <span
                className="search-icon-holder float-right"
                onClick={this.setSearchVisibility}
              >
                <SearchIcon className="search-icon" />
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let page;
  let symbols;
  let showHeader;
  let showMenu;
  let displaySearch;
  let isFilterVisible;
  let showCashier;
  let showSwitchDevice;

  let { q } = queryString.parse(ownProps.location.search);

  try {
    showCashier = state.profile.get("showCashier");
  } catch (err) {}
  try {
    showSwitchDevice = state.profile.get("showSwitchDevice");
  } catch (err) {}

  try {
    let pageId = ownProps.match.params.page;
    if (pageId === "myQT") {
      page = state.pages.get("wl-pages").find((page) => {
        return page.get("rel") === pageId;
      });
      isFilterVisible = false;
    } else {
      let mainPage = state.pages.get("wl-pages").find((page) => {
        return page.get("rel") === "webLobbyMain";
      });
      page = mainPage.get("content").find((page) => {
        return page.get("rel") === pageId;
      });
      isFilterVisible = state.config.get("filtersVisible");
    }

    let exitLink = state.profile.get("links").find((link) => {
      return link.get("rel") === "exit";
    });

    let betHistoryLink = state.profile.get("links").find((link) => {
      return link.get("rel") === "service.url";
    });

    let gameRoundHistoryLink = state.profile.get("links").find((link) => {
      return link.get("rel") === "games-round-history";
    });

    let continuePlayingLink = state.profile.get("links").find((link) => {
      return link.get("rel") === "categories-home.recentlyplayed";
    });

    if (!isEmpty(state.user.getIn(["symbol"]))) {
      let symbolList = state.user.getIn(["symbol"]);
      symbolList = symbolList.map((symbol) => {
        return String.fromCharCode(symbol);
      });
      symbols = symbolList.toJSON().join("");
    }

    showHeader =
      !isEmpty(state.user.get("displayName")) ||
      !isEmpty(state.user.get("balance")) ||
      state.user.get("showLanguage") ||
      exitLink ||
      betHistoryLink ||
      continuePlayingLink ||
      gameRoundHistoryLink ||
      showCashier ||
      showSwitchDevice;

    showMenu =
      !isEmpty(state.user.get("displayName")) ||
      state.user.get("showLanguage") ||
      exitLink ||
      betHistoryLink ||
      continuePlayingLink ||
      gameRoundHistoryLink ||
      showCashier ||
      showSwitchDevice;

    displaySearch = isEmpty(state.links.get("search"));
  } catch (err) {}

  return {
    showCashier,
    showSwitchDevice,
    showHeader,
    showMenu,
    displaySearch,
    page,
    displayName: state.user.get("displayName"),
    balance: state.user.get("balance"),
    currency: state.user.get("currency"),
    decimalPlaces: state.links.get("decimalPlaces"),
    symbol: symbols,
    isMobile: state.window.get("isMobile"),
    scrollY: state.window.getIn(["scroll", "y"]),
    filtersVisible: isFilterVisible,
    sessionId: state.security.get("sessionId"),
    showSearch: state.application.get("showSearch"),
    q: q,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(ActionCreator, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return Object.assign({}, stateProps, ownProps, {
    logout: () => dispatchProps.logout(),
    setMenuVisibility: (value) => dispatchProps.setMenuVisibility(value),
    setSearchVisibility: (value) => dispatchProps.setSearchVisibility(value),
    navigateTo: (path) => ownProps.history.push(path),
  });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Navigation);
