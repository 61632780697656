import React, { Component, Fragment } from "react";
import ReactGA from "react-ga4";
import { connect } from "react-redux";
import { fromJS } from "immutable";
import { bindActionCreators } from "redux";
import { ActionCreator } from "actions";
import * as queryString from "query-string";
import "containers/SearchQuick/styles.scss";
import { ProviderTypeSkeleton } from "component/listItemSkeleton";
import { ProviderTypeListItem, NoImageTypeListItem } from "component/listItem";
import { VericalList } from "component/list";
import { ReactComponent as PuzzlePieceIcon } from "assets/icons/puzzle_piece.svg";
import { ReactComponent as PantoneIcon } from "assets/icons/pantone.svg";
import { ReactComponent as GearsIcon } from "assets/icons/gears.svg";

class SearchQuick extends Component {
  constructor(props) {
    super(props);
    this.setSearchQuick = this._setSearchQuick.bind(this);
    this.onReachEnd = this._onReachEnd.bind(this);
    this.onShowMoreHandler = this._onShowMoreHandler.bind(this);
    this.state = {
      searchQuickLinks: null,
      searchQuick: null,
      searchQuickIndex: 0,
    };
  }

  _onShowMoreHandler(rel, route) {
    let params = {
      showMore: rel,
      route: btoa(encodeURIComponent(JSON.stringify(route.toJSON()))),
    };
    let newLoc = `${this.props.location.pathname}?${queryString.stringify(
      params
    )}`;
    this.props.navigateTo(newLoc);
  }

  _setSearchQuick(value) {
    let search = queryString.parse(this.props.location.search);
    search.searchQuick = value;
    let newLoc = `${this.props.location.pathname}?${queryString.stringify(
      search
    )}`;
    this.props.navigateTo(newLoc);
  }

  _onReachEnd() {
    let searchQuick = this.state.searchQuick;
    let currentSearchQuick = searchQuick.get(this.props.searchQuick);

    let { totalPages, page, items, isLoading } = currentSearchQuick.toJSON();

    if (page < totalPages && !isLoading) {
      searchQuick = searchQuick.setIn(
        [this.props.searchQuick, "isLoading"],
        true
      );
      this.setState({
        searchQuick,
      });

      let nextPage = page + 1;
      let params = {
        size: 20,
        page: nextPage,
      };

      this.props
        .fetchSearchQuick(this.props.quickLink, params)
        .then((resp) => {
          resp.items = items.concat(resp.items);
          searchQuick = searchQuick.mergeIn([this.props.searchQuick], resp);
          searchQuick = searchQuick.setIn(
            [this.props.searchQuick, "isLoading"],
            false
          );
          this.setState({
            searchQuick,
          });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  getRandomColors() {
    let hue = Math.floor(360 * Math.random());
    let saturation = Math.floor(60 + 80 * Math.random());
    let lightness = Math.floor(15 + 30 * Math.random());

    let cssHSL = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    let cssHSLDark = `hsl(${hue}, ${saturation + 15}%, ${lightness + 15}%)`;

    return [cssHSL, cssHSLDark];
  }

  setGAparameters() {
    let search = queryString.parse(this.props.location.search);
    if (search.searchQuick) {
      ReactGA.event({
        category: "Quick Search",
        action: `quicksearch.${search.searchQuick.split(".")[1]}`,
        label: search.searchQuick,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.q !== nextProps.q && nextProps.q === "") {
      if (this.props.searchQuick === undefined && this.state.searchQuickLinks) {
        let search = queryString.parse(nextProps.location.search);
        search.searchQuick = this.state.searchQuickLinks.getIn([
          this.state.searchQuickIndex,
          "rel",
        ]);
        let newLoc = `${this.props.location.pathname}?${queryString.stringify(
          search
        )}`;
        this.props.navigateTo(newLoc);
      }
    }

    if (
      this.props.searchQuick !== nextProps.searchQuick &&
      nextProps.searchQuick
    ) {
      let searchQuickIndex = this.state.searchQuickLinks.findIndex((link) => {
        return link.get("rel") === nextProps.searchQuick;
      });
      this.setState({
        searchQuickIndex,
      });
    }

    if (
      this.props.searchQuickLinks !== nextProps.searchQuickLinks &&
      nextProps.searchQuickLinks
    ) {
      return this.props
        .fetchSearchQuickLinks(nextProps.searchQuickLinks)
        .then((resp) => {
          let searchQuickLinks = fromJS(resp);
          this.setState({
            searchQuickLinks,
          });
          this.props.setSearchQuickLinks(searchQuickLinks);
          return searchQuickLinks;
        })
        .catch((err) => {
          console.error(err);
        });
    }

    if (this.props.quickLink !== nextProps.quickLink && nextProps.quickLink) {
      if (
        this.state.searchQuick &&
        this.state.searchQuick.getIn([nextProps.quickLink.get("rel")])
      ) {
        return;
      }

      let params = {
        size: 20,
      };

      let searchQuick = this.state.searchQuick
        ? this.state.searchQuick
        : fromJS({});
      searchQuick = searchQuick.setIn(
        [nextProps.quickLink.get("rel")],
        fromJS({})
      );
      searchQuick = searchQuick.setIn(
        [nextProps.quickLink.get("rel"), "isLoading"],
        true
      );

      this.setState({
        searchQuick,
      });

      return this.props
        .fetchSearchQuick(nextProps.quickLink, params)
        .then((resp) => {
          let searchQuick = this.state.searchQuick
            ? this.state.searchQuick
            : fromJS({});
          searchQuick = searchQuick.mergeIn(
            [nextProps.quickLink.get("rel")],
            fromJS(resp)
          );

          searchQuick
            .getIn([nextProps.quickLink.get("rel"), "items"])
            .forEach((item, index) => {
              let rnd = this.getRandomColors();
              searchQuick = searchQuick.setIn(
                [
                  nextProps.quickLink.get("rel"),
                  "items",
                  index,
                  "randomColors",
                ],
                rnd
              );
            });

          searchQuick = searchQuick.setIn(
            [nextProps.quickLink.get("rel"), "isLoading"],
            false
          );

          this.setState({
            searchQuick,
          });
          return this.props.setSearchQuick(searchQuick);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  render() {
    if (this.props.q !== "") {
      return null;
    }

    return (
      <div className={`search-quick ${this.props.show ? "" : "d-none"}`}>
        <div className="row justify-content-center quick-links">
          <div className="col-auto">
            {this.state.searchQuickLinks &&
              this.state.searchQuickLinks.entrySeq().map(([key, link]) => {
                let selected =
                  (!this.props.searchQuick && key === 0) ||
                  link.get("rel") === this.props.searchQuick;
                let Icon;
                switch (link.get("rel")) {
                  case "searchQuickTypes.themes":
                    Icon = PantoneIcon;
                    break;
                  case "searchQuickTypes.providers":
                    Icon = GearsIcon;
                    break;
                  case "searchQuickTypes.specialFeatures":
                    Icon = PuzzlePieceIcon;
                    break;
                  default:
                    Icon = null;
                    break;
                }

                return (
                  <span
                    key={key}
                    className={`btn ${
                      selected ? "btn-danger" : "btn-secondary"
                    } ${this.state.isLoading ? "disabled" : ""}  btn-sm `}
                    onClick={() => {
                      if (!this.state.isLoading) {
                        this.setSearchQuick(link.get("rel"));
                      }
                    }}
                  >
                    {Icon && (
                      <i>
                        <Icon />
                      </i>
                    )}
                    {link.get("name")}
                  </span>
                );
              })}
            {this.props.searchQuickLinks && !this.state.searchQuickLinks && (
              <Fragment>
                <span className={`btn btn-secondary btn-sm disabled dummy`} />
                <span className={`btn btn-secondary btn-sm disabled dummy`} />
                <span className={`btn btn-secondary btn-sm disabled dummy`} />
              </Fragment>
            )}
          </div>
        </div>
        {this.state.searchQuick &&
          this.state.searchQuick.entrySeq().map(([itemsKey, sqItems]) => {
            if (itemsKey !== this.props.searchQuick) {
              return null;
            }

            return (
              <Fragment key={itemsKey}>
                <VericalList
                  className={
                    itemsKey === this.props.searchQuick ? "" : "d-none"
                  }
                  onReachEnd={this.onReachEnd}
                >
                  {sqItems.get("items") &&
                    sqItems.get("items").map((item, key) => {
                      let route = item.getIn(["links", 0]);
                      if (itemsKey === "searchQuickTypes.providers") {
                        return (
                          <div className="col" key={key}>
                            <ProviderTypeListItem
                              game={item}
                              onClick={(rel) => {
                                this.onShowMoreHandler(route.get("rel"), route);
                                this.setGAparameters();
                              }}
                              imageFormat={this.props.imageFormat}
                            />
                          </div>
                        );
                      }
                      return (
                        <div className="col" key={key}>
                          <NoImageTypeListItem
                            isMobile={this.props.isMobile}
                            game={item}
                            onClick={(rel) => {
                              this.onShowMoreHandler(route.get("rel"), route);
                              this.setGAparameters();
                            }}
                          />
                        </div>
                      );
                    })}
                  {sqItems.get("isLoading") &&
                    itemsKey === "searchQuickTypes.providers" && (
                      <Fragment>
                        <div className="col">
                          <ProviderTypeSkeleton />
                        </div>
                        <div className="col">
                          <ProviderTypeSkeleton />
                        </div>
                        <div className="col">
                          <ProviderTypeSkeleton />
                        </div>
                        <div className="col">
                          <ProviderTypeSkeleton />
                        </div>
                        <div className="col">
                          <ProviderTypeSkeleton />
                        </div>
                        <div className="col">
                          <ProviderTypeSkeleton />
                        </div>
                        <div className="col">
                          <ProviderTypeSkeleton />
                        </div>
                        <div className="col">
                          <ProviderTypeSkeleton />
                        </div>
                        <div className="col">
                          <ProviderTypeSkeleton />
                        </div>
                        <div className="col">
                          <ProviderTypeSkeleton />
                        </div>
                        <div className="col">
                          <ProviderTypeSkeleton />
                        </div>
                        <div className="col">
                          <ProviderTypeSkeleton />
                        </div>
                      </Fragment>
                    )}
                  {sqItems.get("isLoading") &&
                    itemsKey !== "searchQuickTypes.providers" && (
                      <Fragment>
                        <div className="col">
                          <NoImageTypeListItem />
                        </div>
                        <div className="col">
                          <NoImageTypeListItem />
                        </div>
                        <div className="col">
                          <NoImageTypeListItem />
                        </div>
                        <div className="col">
                          <NoImageTypeListItem />
                        </div>
                        <div className="col">
                          <NoImageTypeListItem />
                        </div>
                        <div className="col">
                          <NoImageTypeListItem />
                        </div>
                        <div className="col">
                          <NoImageTypeListItem />
                        </div>
                        <div className="col">
                          <NoImageTypeListItem />
                        </div>
                        <div className="col">
                          <NoImageTypeListItem />
                        </div>
                        <div className="col">
                          <NoImageTypeListItem />
                        </div>
                        <div className="col">
                          <NoImageTypeListItem />
                        </div>
                        <div className="col">
                          <NoImageTypeListItem />
                        </div>
                      </Fragment>
                    )}
                </VericalList>
              </Fragment>
            );
          })}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let { q, searchQuick } = queryString.parse(ownProps.location.search);
  let isMobile = state.window.get("isMobile");
  let searchQuickLinks = state.links.get("searchQuickLinks");
  let quickLinks = state.search.get("quickLinks");
  let quickLink;
  try {
    quickLink =
      searchQuick !== undefined
        ? quickLinks.find((link) => {
            return link.get("rel") === searchQuick;
          })
        : quickLinks.get(0);
  } catch (err) {}

  return {
    q,
    isMobile,
    searchQuick,
    searchQuickLinks,
    quickLink,
    imageFormat: state.window.get("imageFormat"),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(ActionCreator, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return Object.assign({}, stateProps, ownProps, {
    navigateTo: (path) => ownProps.history.push(path),
    fetchSearchQuickLinks: (route) =>
      dispatchProps.fetchSearchQuickLinks(route),
    setSearchQuickLinks: (data) => dispatchProps.setSearchQuickLinks(data),
    fetchSearchQuick: (route, params) =>
      dispatchProps.fetchSearchQuick(route, params),
    setSearchQuick: (data) => dispatchProps.setSearchQuick(data),
  });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(SearchQuick);
